import { Guid } from '@app-types/guid.type';
import { FilterModel } from '@components/filters/filter-dialog/filter-dialog.component';

export interface TrajectoryLabelDto {
  id: Guid;
  name: string;
}

export interface TrajectoryDto {
  id: Guid;
  name: string;
  frequency: number;
  detectionPercentage: number;
  frequencyTrendMiniatureContentPath: string;
  energyTrendMiniatureContentPath: string;
  labels: TrajectoryLabelDto[];
}

export interface SensorTrajectoriesDto {
  status: string;
  nextComputationDate: Date | null;
  trajectories: TrajectoryDto[];
}

export interface Trajectory {
  id: Guid;
  name: string;
  frequency: number;
  detectionPercentage: number;
  frequencyTrendMiniatureContentPath: string;
  energyTrendMiniatureContentPath: string;
  isLabeled: boolean;
}

export interface SensorTrajectories {
  status: string;
  nextComputationDate: Date | null;
  trajectories: Trajectory[];
}

const trajectoryName = (t: TrajectoryDto) => (t.labels.length > 0 ? t.labels.map(l => l.name).join(', ') : t.name);

export const mapFromDto = (dto: SensorTrajectoriesDto): SensorTrajectories => ({
  ...dto,
  trajectories: dto.trajectories.map(t => ({
    id: t.id,
    frequency: t.frequency,
    detectionPercentage: t.detectionPercentage,
    frequencyTrendMiniatureContentPath: t.frequencyTrendMiniatureContentPath,
    energyTrendMiniatureContentPath: t.energyTrendMiniatureContentPath,
    name: trajectoryName(t),
    isLabeled: t.labels.length != 0,
  })),
});

export function filterByFrequency(trajectories: Trajectory[] | null, filter: FilterModel | undefined) {
  if (filter && trajectories) {
    return trajectories.filter(d => filter.min <= d.frequency && d.frequency <= filter.max);
  }
  return trajectories;
}

export function filterByDetectionPercentage(trajectories: Trajectory[] | null, filter: FilterModel | undefined) {
  if (filter && trajectories) {
    return trajectories.filter(d => filter.min <= d.detectionPercentage && d.detectionPercentage <= filter.max);
  }
  return trajectories;
}
