import { CommonModule } from '@angular/common';
import { Component, computed, effect, input, output, signal, viewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FilterComponent } from '@components/filters/filter/filter.component';
import { ScrollableBorderedContainerComponent } from '@components/scrollable-bordered-container/scrollable-bordered-container.component';
import { authFeature } from '@features/auth/shared/store/auth.feature';
import { TrajectoryService } from '@features/sensor-trajectories/services/trajectory/trajectory.service';
import { TrendType } from '@features/sensor-trajectories/shared/interfaces/trajectory-trend.interface';
import {
  filterByDetectionPercentage,
  filterByFrequency,
  Trajectory,
} from '@features/sensor-trajectories/shared/interfaces/trajectory.interface';
import { sensorTrajectoriesFeature } from '@features/sensor-trajectories/shared/store/sensor-trajectories.feature';
import { SensorId } from '@features/sensors/shared/interfaces/sensor.interface';
import { MaterialModule } from '@modules/material.module';
import { Store } from '@ngrx/store';
import { DialogService } from '@services/dialog.service';
import { FilterDialogService } from '@services/filter-dialog.service';
import { parseProcessingState } from '@shared/interfaces/processing-status';

import { OptionFilterComponent } from '@components/filters/filter/option-filter.component';
import {
  OptionFilterModel,
  OptionModel,
} from '@components/filters/option-filter-dialog/option-filter-dialog.component';
import { FilterModel } from '../../../../shared/components/filters/filter-dialog/filter-dialog.component';
import { TrendMiniatureComponent } from '../trend-miniature/trend-miniature.component';

@Component({
  selector: 'app-sensor-trajectories',
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    ScrollableBorderedContainerComponent,
    TrendMiniatureComponent,
    FilterComponent,
    OptionFilterComponent,
  ],
  providers: [TrajectoryService, DialogService, FilterDialogService],
  templateUrl: './sensor-trajectories.component.html',
  styles: ``,
})
export class SensorTrajectoriesComponent {
  sensorId = input.required<SensorId>();
  navigateToGraph = output();
  matSort = viewChild(MatSort);

  canRecompute = this.store.selectSignal(authFeature.selectCanRecompute);
  sensorTrajectoriesNextComputationDate = this.store.selectSignal(sensorTrajectoriesFeature.selectNextComputationDate);
  statusItemDescription = computed(() => parseProcessingState(this.sensorTrajectoriesStatus()));

  dataSource = computed(() => {
    const filteredTrajectories = this.filteredTrajectories();
    if (filteredTrajectories) {
      const dataSource = new MatTableDataSource(filteredTrajectories);
      dataSource.paginator = this.paginator() ?? null;
      dataSource.sort = this.matSort() ?? null;
      return dataSource;
    }
    return undefined;
  });
  columns = ['name', 'frequency', 'detectionPercentage', 'frequencyMiniature', 'energyMiniature', 'filler'];

  hasSelected = computed(() => this.nSelected(TrendType.Frequency)() + this.nSelected(TrendType.Energy)() > 0);
  partiallySelected = (type: TrendType) =>
    computed(() => this.nSelected(type)() > 0 && this.nSelected(type)() !== this.filteredTrajectories()?.length);
  allSelected = (type: TrendType) => computed(() => this.nSelected(type)() === this.filteredTrajectories()?.length);

  filtersValues = (property: 'frequency' | 'detectionPercentage') =>
    computed(() => this.trajectories()?.map(t => t[property]) ?? []);
  nameOptions: OptionModel[] = [
    { id: 1, name: 'With label' },
    { id: 2, name: 'Without label' },
  ];
  nameFilter = signal<OptionFilterModel | undefined>(undefined);
  frequencyFilter = signal<FilterModel | undefined>(undefined);
  detectionPercentageFilter = signal<FilterModel | undefined>(undefined);

  private trajectories = this.trajectoryService.selectTrajectories();
  private filteredTrajectories = computed(() =>
    this.filterByNameOption(
      filterByDetectionPercentage(
        filterByFrequency(this.trajectories(), this.frequencyFilter()),
        this.detectionPercentageFilter()
      ) ?? []
    )
  );

  private sensorTrajectoriesStatus = this.store.selectSignal(sensorTrajectoriesFeature.selectStatus);

  private paginator = viewChild(MatPaginator);
  private selected = {
    [TrendType.Frequency]: signal(new Set<string>()),
    [TrendType.Energy]: signal(new Set<string>()),
  };
  private nSelected = (type: TrendType) => computed(() => this.selected[type]().size);

  constructor(
    private trajectoryService: TrajectoryService,
    private store: Store,
    private dialogService: DialogService
  ) {
    effect(
      () => {
        this.refresh();
      },
      { allowSignalWrites: true }
    );
  }

  toggle(trendType: TrendType, id: string) {
    this.selected[trendType].update(set => {
      if (!set.delete(id)) {
        set.add(id);
      }
      return new Set(set);
    });
  }

  isSelected(trendType: TrendType, id: string) {
    return this.selected[trendType]().has(id);
  }

  refresh() {
    this.trajectoryService.fetchTrajectories(this.sensorId());
  }

  async recompute() {
    const recompute = await this.dialogService.confirm({
      title: 'Recompute Trajectories',
      descriptions: ['Do you want to recompute all trajectories?', 'It might take some time.'],
    });

    if (recompute) {
      this.trajectoryService.setCurrentSensorTrackingAsDirty(this.sensorId());
    }
  }

  visualize() {
    this.selectedTrajectories(TrendType.Frequency).forEach(trajectory => {
      this.trajectoryService.visualizeFrequency(this.sensorId(), trajectory);
    });
    this.unselectAll(TrendType.Frequency);

    this.selectedTrajectories(TrendType.Energy).forEach(trajectory => {
      this.trajectoryService.visualizeEnergy(this.sensorId(), trajectory);
    });
    this.unselectAll(TrendType.Energy);

    this.navigateToGraph.emit();
  }

  trendTypeCheckboxChange(type: TrendType, checked: boolean) {
    if (checked) {
      this.selectAll(type);
    } else {
      this.unselectAll(type);
    }
  }

  getTrendContentPath(trajectory: Trajectory, trendType: TrendType): string {
    switch (trendType) {
      case TrendType.Frequency:
        return trajectory.frequencyTrendMiniatureContentPath;
      case TrendType.Energy:
        return trajectory.energyTrendMiniatureContentPath;
    }
  }

  filterFrequency(filter: FilterModel | undefined) {
    this.frequencyFilter.set(filter);
    this.setSelected();
  }

  filterDetectionPercentage(filter: FilterModel | undefined) {
    this.detectionPercentageFilter.set(filter);
    this.setSelected();
  }

  filterName(filter: OptionFilterModel | undefined) {
    this.nameFilter.set(filter);
    this.setSelected();
  }

  TrendType = TrendType;

  private selectAll(type: TrendType) {
    this.selected[type].set(new Set(this.filteredTrajectories()?.map(t => t.id)));
  }

  private unselectAll(type: TrendType) {
    this.selected[type].set(new Set());
  }

  private selectedTrajectories(type: TrendType) {
    const selectedIds = this.selected[type]();
    return (this.filteredTrajectories() ?? []).filter(t => selectedIds.has(t.id));
  }

  private setSelected() {
    for (const type of [TrendType.Frequency, TrendType.Energy]) {
      this.selected[type].set(new Set(this.selectedTrajectories(type).map(t => t.id)));
    }
  }

  private filterByNameOption(trajectories: Trajectory[]) {
    const nameFilter = this.nameFilter();

    if (
      nameFilter?.valuedOptions.find(o => o.id === 1)?.value === false &&
      nameFilter?.valuedOptions.find(o => o.id === 2)?.value === false
    ) {
      return [];
    }
    let filteredTrajectories = trajectories;
    if (nameFilter?.valuedOptions.find(o => o.id === 1)?.value) {
      filteredTrajectories = filteredTrajectories.filter(t => t.isLabeled);
    }
    if (nameFilter?.valuedOptions.find(o => o.id === 2)?.value) {
      filteredTrajectories = filteredTrajectories.filter(t => !t.isLabeled);
    }

    return filteredTrajectories;
  }
}
