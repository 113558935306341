<div class="flex w-full flex-col">
  <app-graph-wrapper
    name="Raw signal"
    class="mx-2"
    xTitle="Time (ms)"
    yTitle="Amplitude"
    [data]="graphData()"
    [showDelete]="false"
    [showLegend]="false"
    graphHeight="70vh" />
</div>
