import { Component, signal } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { MaterialModule } from '@modules/material.module';
import { SidenavComponent } from '@navigation/sidenav/sidenav.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet, MaterialModule, SidenavComponent],
})
export class AppComponent {
  sidenavExpanded = signal<boolean>(true);

  toggleSidenavExpansion = () => {
    this.sidenavExpanded.update(value => !value);
  };
}
