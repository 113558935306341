import { Injectable } from '@angular/core';
import { SensorGraphsActions } from '@features/sensor-graphs/shared/store/sensor-graphs.actions';
import { TrajectoriesApiService } from '@features/sensor-trajectories/services/trajectories-api/trajectories-api.service';
import { catchApiError } from '@modules/error-handling/app-error.operators';
import { isNotFoundStatus } from '@modules/error-handling/app-error/app-error.class';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs';

import { mapFromDto } from '../interfaces/trajectory.interface';
import { getTrajectoryTrendCurve } from '../utils/trajectory-trend';
import { SensorTrajectoriesActions } from './sensor-trajectories.actions';

@Injectable()
export class SensorTrajectoriesEffect {
  constructor(
    private actions$: Actions,
    private trajectoriesApiService: TrajectoriesApiService
  ) {}

  fetchSensorTrajectoriesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SensorTrajectoriesActions.trajectoriesFetchRequested),
      mergeMap(({ sensorId }) =>
        this.trajectoriesApiService.getSensorTrajectories(sensorId).pipe(
          map(sensorTrajectories =>
            SensorTrajectoriesActions.trajectoriesFetched({
              sensorId,
              sensorTrajectories: mapFromDto(sensorTrajectories),
            })
          ),
          catchApiError(false, error =>
            isNotFoundStatus(error)
              ? SensorTrajectoriesActions.trajectoriesNotFound({ sensorId })
              : SensorTrajectoriesActions.trajectoriesFetchFailed({ sensorId })
          )
        )
      )
    )
  );

  visualizeTrajectoryFrequencyTrendEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SensorTrajectoriesActions.trajectoriesFrequencyTrendVisualizeRequested),
      mergeMap(({ sensorId, trajectory }) =>
        this.trajectoriesApiService.getTrajectoryFrequencyTrend(trajectory.id).pipe(
          map(trajectoryTrend =>
            SensorGraphsActions.addCurve({
              sensorId,
              curveId: trajectoryTrend.id,
              curve: getTrajectoryTrendCurve(trajectoryTrend, `${trajectory.name} Frequency`),
            })
          ),
          catchApiError(false, () => SensorTrajectoriesActions.trajectoriesFrequencyTrendFetchFailed())
        )
      )
    )
  );

  visualizeTrajectoryEnergyTrendEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SensorTrajectoriesActions.trajectoriesEnergyTrendVisualizeRequested),
      mergeMap(({ sensorId, trajectory }) =>
        this.trajectoriesApiService.getTrajectoryEnergyTrend(trajectory.id).pipe(
          map(trajectoryTrend =>
            SensorGraphsActions.addCurve({
              sensorId,
              curveId: trajectoryTrend.id,
              curve: getTrajectoryTrendCurve(trajectoryTrend, `${trajectory.name} Energy`),
            })
          ),
          catchApiError(false, () => SensorTrajectoriesActions.trajectoriesEnergyTrendFetchFailed())
        )
      )
    )
  );
}
