<div class="flex flex-col gap-2 p-4">
  <span class="font-semibold">Curves</span>
  <div class="relative flex w-full flex-col gap-1">
    @if (selection(); as selection) {
      @for (groupLegend of groupedData(); track groupLegend.groupName) {
        <div
          class="flex w-full flex-col gap-0 rounded-md border border-slate-400 p-0"
          [ngClass]="{
            hatched: !isGroupVisible(groupLegend.groupName),
          }">
          @if (nGroups() > 1) {
            <div
              class="inline-flex w-full items-center p-2 font-semibold"
              [attr.data-testid]="'group-' + groupLegend.groupName">
              <mat-icon class="flex-grow">tab_group</mat-icon>
              <mat-button-toggle-group
                class="toggle-group-small"
                [value]="getGroupToggle(groupLegend.groupName)"
                [multiple]="true"
                [hideMultipleSelectionIndicator]="true"
                name="fontStyle"
                aria-label="Font Style"
                (change)="groupTogglesChanged($event, groupLegend.groupName)">
                <mat-button-toggle value="hide" [attr.data-testid]="'group-' + groupLegend.groupName + '-hide'">
                  <mat-icon>visibility_off</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle value="single" [attr.data-testid]="'group-' + groupLegend.groupName + '-single'">
                  <mat-icon>stack_star</mat-icon>
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          }
          @for (curve of groupLegend.curves; track curve.id; let first = $first; let last = $last) {
            <div
              class="m-0 inline-flex w-full items-center gap-2 border-transparent p-2 hover:border-slate-400"
              (click)="legendClicked(curve.id)"
              (mouseenter)="onCurveEnter(curve.id, isGroupVisible(groupLegend.groupName))"
              (mouseleave)="onCurveLeave()"
              [ngClass]="{
                'border-b': !last,
                'border-t': !first || nGroups() > 1,
                'bg-blue-200': selection.has(curve.id),
              }"
              [attr.data-testid]="'curve-' + curve.id">
              <span class="flex-grow truncate" [matTooltip]="getCurveName(curve)" [matTooltipShowDelay]="1000">
                {{ getCurveName(curve) }}
              </span>
              <app-trajectory-graph-color-select
                [color]="curve.color!"
                (colorChanged)="colorSelected.emit({ name: curve.id, color: $event })" />
              <button mat-icon-button class="graph-settings-button text-red-700" (click)="onRemoveCurve(curve.id)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          }
        </div>
      }
    }
  </div>
  <div class="absolute bottom-1 right-1 flex flex-row gap-2" [style.visibility]="hasSelection() ? 'visible' : 'hidden'">
    <button mat-raised-button (click)="groupSelection()" class="primary-button" data-testid="group-button">
      <mat-icon>move_group</mat-icon>Group
    </button>
    <button mat-raised-button (click)="ungroupSelection()" class="error-button" data-testid="ungroup-button">
      <mat-icon>ad_group_off</mat-icon>Ungroup
    </button>
  </div>
</div>
