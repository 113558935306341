import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { Component, computed, effect, ElementRef, input, output, signal, viewChild, viewChildren } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MaterialModule } from '@modules/material.module';

import { ColDef, DefaultSortConfiguration, FilterPredicate, TableOptions } from './interfaces/interfaces';
import { IdListSelectionModel } from './utilities/selection-model';

const defaultMinBufferedItemsCount = 10;
const defaultBufferedItemsCountMaxToMinRatio = 1.2;

@Component({
  selector: 'app-fixed-size-item-virtual-scroll-table',
  standalone: true,
  imports: [MaterialModule, ScrollingModule, CommonModule],
  templateUrl: './fixed-size-item-virtual-scroll-table.component.html',
})
export class FixedSizeItemVirtualScrollTableComponent<
  TId,
  TData,
  TOptions extends TableOptions<TId, TData>,
  TFilterPredicate extends FilterPredicate<TData>,
> {
  public data = input.required<TData[]>();
  public options = input.required<TOptions>();

  public filterPredicate = input<TFilterPredicate | undefined>(undefined);

  public selectionChanged = output<TData[]>();

  public scrollViewport = viewChild<CdkVirtualScrollViewport>('scroll_viewport');
  public tableDiv = viewChild<ElementRef<HTMLDivElement>>('tableDiv');
  public headerColumns = viewChildren('header_column', { read: ElementRef });

  public sort = viewChild.required(MatSort);

  public readonly SELECTION_COLUMN_ID = 'selection';

  private _resizeObserver: ResizeObserver;
  private _columnWidths = signal<number[]>([]);
  private _selectionModel: IdListSelectionModel<TId> = new IdListSelectionModel<TId>();

  constructor() {
    effect(
      () => {
        const viewport = this.scrollViewport();
        // Needed in order for the viewport to have time for its clientWidth to be set properly.
        // Otherwise, we cannot account for the scrollbar width when computing the viewport and column widths.
        setTimeout(() => {
          this.viewportInitialized.set(viewport !== undefined);
        }, 0);
      },
      { allowSignalWrites: true }
    );

    effect(() => {
      this.dataSource().sort = this.sort();
    });

    effect(() => {
      const dataSource = this.dataSource();

      dataSource.filterPredicate = this.filterPredicate() ?? (() => true);

      // Filtering is only triggered if a filter string is present, even if the
      // string is not taken into account during filtering.
      // Therefore, for simplicity, we set the string every time we reset the predicate.
      dataSource.filter = 'a';
    });

    effect(
      () => {
        const headerColumns = this.headerColumns();

        this._columnWidths.set(headerColumns.map(column => column.nativeElement.offsetWidth));
      },
      { allowSignalWrites: true }
    );

    this._resizeObserver = new ResizeObserver(() => {
      this._columnWidths.set(this.headerColumns().map(column => column.nativeElement.offsetWidth));
    });

    effect(() => {
      const tableDiv = this.tableDiv();

      if (tableDiv === undefined) {
        return;
      }

      this._resizeObserver.observe(tableDiv.nativeElement);
    });

    effect(() => {
      const initialized = this.viewportInitialized();

      if (!initialized) {
        return;
      }

      const newSelection = this._selectionModel.selectionSignal();
      const items = this.data();

      const idRetrievalFn = this.options().itemIdRetrieveFn;

      const selectedItems = newSelection.map(id => items.find(item => idRetrievalFn(item) === id)!);

      this.selectionChanged.emit(selectedItems);
    });
  }

  private _scrollBarWidth = computed(() => {
    const initialized = this.viewportInitialized();

    if (!initialized) {
      return 0;
    }

    const viewport = this.scrollViewport()!;

    const viewportClientWidth = viewport.measureViewportSize('horizontal');
    const viewportOffsetWidth = viewport.elementRef.nativeElement.offsetWidth;

    return viewportOffsetWidth - viewportClientWidth;
  });

  public defaultSortOptions = computed(() => {
    const columnDefinitions = this.columnDefinitions().filter(def => def);

    const defaultOptions: DefaultSortConfiguration = {
      disableClear: false,
      activeColumnId: columnDefinitions.find(definition => definition.sortable ?? false)?.propertyName ?? '',
      direction: 'asc',
    };

    const providedSortOptions = this.options().defaultSortConfiguration;

    if (providedSortOptions === undefined) {
      return defaultOptions;
    }

    if (providedSortOptions.disableClear !== undefined) {
      defaultOptions.disableClear = providedSortOptions.disableClear!;
    }

    if (
      providedSortOptions.activeColumnId !== undefined &&
      columnDefinitions.some(column => column.propertyName === providedSortOptions.activeColumnId!)
    ) {
      defaultOptions.activeColumnId = providedSortOptions.activeColumnId!;
    }

    if (providedSortOptions.direction !== undefined) {
      defaultOptions.direction = providedSortOptions.direction!;
    }

    return defaultOptions;
  });

  public hasRowSelection = computed(() => this.options().rowSelectionConfiguration !== undefined);

  public selectionColumnIsBefore = computed(
    () => (this.options().rowSelectionConfiguration?.placement ?? 'before') === 'before'
  );

  public emptyDataMessage = computed(() => this.options().emptyDataMessage ?? 'No item matches the current criteria.');
  public columnDefinitions = computed(() => this.options().columnDefinitions);

  public itemSize = computed(() => this.options().rowHeight);
  public minBufferedItemsCount = computed(() => {
    const minBufferedItemsCount = this.options().minBufferedItemsCount;

    if (minBufferedItemsCount !== undefined) {
      return Math.max(Math.round(minBufferedItemsCount), 1);
    }

    const maxBufferedItemsCount = this.options().maxAdditionnalBufferedItemsCount;

    if (maxBufferedItemsCount !== undefined) {
      return Math.floor((1 - defaultBufferedItemsCountMaxToMinRatio) * maxBufferedItemsCount);
    }

    return defaultMinBufferedItemsCount;
  });

  public maxBufferedItemsCount = computed(() => {
    const maxAdditionnalBufferedItemsCount = this.options().maxAdditionnalBufferedItemsCount;
    const computedMinBufferedItemsCount = this.minBufferedItemsCount();

    if (maxAdditionnalBufferedItemsCount !== undefined) {
      return computedMinBufferedItemsCount + Math.max(Math.round(maxAdditionnalBufferedItemsCount), 1);
    }

    return Math.ceil((1 + defaultBufferedItemsCountMaxToMinRatio) * computedMinBufferedItemsCount);
  });

  public minBufferPx = computed(() => this.itemSize() * this.minBufferedItemsCount());
  public maxBufferPx = computed(() => this.itemSize() * this.maxBufferedItemsCount());

  public columnIds = computed(() => {
    const definedColumnsIds = this.columnDefinitions().map(column => column.propertyName);

    if (!this.hasRowSelection()) {
      return definedColumnsIds;
    } else if (this.selectionColumnIsBefore()) {
      return [this.SELECTION_COLUMN_ID, ...definedColumnsIds];
    } else {
      return [...definedColumnsIds, this.SELECTION_COLUMN_ID];
    }
  });

  public usesSorting = computed(() => true);

  public dataSource = computed(() => {
    const source = new MatTableDataSource(this.data());

    return source;
  });

  public rowBackgroundColor = (item: TData, isEven: boolean) => {
    let color = '';

    const isSelected = this.itemIsSelected(item);
    const backgroundColorRetrieveFn = this.options().selectionBackgrounColorRetrieveFn;

    if (backgroundColorRetrieveFn !== undefined && isSelected) {
      color = backgroundColorRetrieveFn(item);
    } else {
      color = isEven ? '#E2E8F0' : '#F1F5F9';
    }

    return color;
  };

  public rowDependentClass = (isLast: boolean) => {
    return isLast ? '' : `border-b border-b-slate-300`;
  };

  private viewportInitialized = signal<boolean>(false);

  public selectionColumnStyle = computed(() => {
    if (!this.hasRowSelection()) {
      return {};
    } else if (this.selectionColumnIsBefore()) {
      return {
        'padding-left': '8px',
      };
    } else {
      const rightPadding = 8 - this._scrollBarWidth();
      return {
        'padding-right': `${rightPadding}px`,
      };
    }
  });

  public dataDependentStyle = computed(() => {
    // As tailwind seems to be treeshaking and therefore removing implementations of classes that are not
    // in the template, we cannot use tailwind classes along with ngClass to set classes
    // dynamically, and therefore need to specify style directly with ngStyle.
    const initialized = this.viewportInitialized();

    if (!initialized) {
      return undefined;
    }

    const viewport = this.scrollViewport()!;

    const scrollbarWidth = this._scrollBarWidth();
    const viewportOffsetWidth = viewport.elementRef.nativeElement.offsetWidth;

    const viewportClientWidth = viewportOffsetWidth - scrollbarWidth;

    const style = {
      display: 'grid',
      width: `${viewportClientWidth}px`,
      height: `${this.itemSize()}px`,
      'grid-template-columns': '',
    };

    const columnWidths = this._columnWidths();

    columnWidths[columnWidths.length - 1] = columnWidths[columnWidths.length - 1] - scrollbarWidth;

    style['grid-template-columns'] = columnWidths.map(width => `${width}px`).join(' ');

    return style;
  });

  public trackByItemId = computed(() => {
    const idRetrievalFn = this.options().itemIdRetrieveFn;

    return (index: number, item: TData): TId => {
      return idRetrievalFn(item);
    };
  });

  public updateItemSelectionState = (item: TData, select: boolean) => {
    const itemId = this.options().itemIdRetrieveFn(item);

    if (select) {
      this._selectionModel.selectId(itemId);
    } else {
      this._selectionModel.unselectId(itemId);
    }
  };

  private _dataIds = computed(() => {
    const idRetrieveFn = this.options().itemIdRetrieveFn;

    return this.data().map(idRetrieveFn);
  });

  public columnCheckboxChecked = computed(() => this._selectionModel.hasSelection());
  public updateColumnSelection = () => {
    if (this.columnCheckboxChecked()) {
      this._selectionModel.clearSelection();
    } else {
      this._selectionModel.setSelection(this._dataIds());
    }
  };

  private itemIsSelected = (item: TData) => {
    const itemId = this.options().itemIdRetrieveFn(item);

    return this._selectionModel.selection().includes(itemId);
  };

  public itemIsSelectedSignal = (item: TData) => computed(() => this.itemIsSelected(item));

  public getTooltip = (item: TData, columnDefinition: ColDef<TData>) => {
    if (columnDefinition.tooltip !== undefined) {
      return columnDefinition.tooltip(item);
    }

    return '';
  };
}
