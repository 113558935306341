<div class="flex h-full w-full flex-col content-stretch items-stretch p-0">
  <app-path-bar
    class="flex-none"
    [equipment]="equipment()"
    [sensor]="sensor()"
    (equipmentClicked)="navigateToEquipment($event)"
    (homeClicked)="navigateToRoot()" />
  @if (sensor(); as sensor) {
    <app-sensor class="flex-grow" [sensorSummary]="sensor" [loading]="isLoading()" />
  } @else {
    <app-entity-not-found entityName="sensor" [notFound]="true" />
  }
</div>
