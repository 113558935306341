<div class="h-full w-full">
  <div class="mat-elevation-z2 h-full w-full overflow-clip rounded-xl">
    <div #tableDiv class="flex h-full w-full flex-col">
      @let _defaultSortOptions = defaultSortOptions();

      @let _hasRowSelection = hasRowSelection();
      @let _selectionColumnIsBefore = selectionColumnIsBefore();

      <mat-table
        matSort
        [matSortDisableClear]="_defaultSortOptions.disableClear"
        [matSortActive]="_defaultSortOptions.activeColumnId"
        [matSortDirection]="_defaultSortOptions.direction"
        class="mat-elevation-z3 w-full select-none">
        @if (_hasRowSelection && _selectionColumnIsBefore) {
          <ng-container [matColumnDef]="SELECTION_COLUMN_ID">
            <mat-header-cell #header_column *matHeaderCellDef class="justify-center py-2">
              <mat-checkbox [checked]="columnCheckboxChecked()" (change)="updateColumnSelection()" />
            </mat-header-cell>
          </ng-container>
        }

        @for (columnDefinition of columnDefinitions(); track columnDefinition.propertyName) {
          <ng-container [matColumnDef]="columnDefinition.propertyName">
            @let _columnIsSortable = columnDefinition.sortable ?? false;

            @if (_columnIsSortable) {
              <mat-header-cell #header_column *matHeaderCellDef mat-sort-header class="justify-center py-2">
                {{ columnDefinition.name }}
              </mat-header-cell>
            } @else {
              <mat-header-cell #header_column *matHeaderCellDef class="justify-center py-2">
                {{ columnDefinition.name }}
              </mat-header-cell>
            }
          </ng-container>
        }

        @if (_hasRowSelection && !_selectionColumnIsBefore) {
          <ng-container [matColumnDef]="SELECTION_COLUMN_ID">
            <mat-header-cell #header_column *matHeaderCellDef class="justify-center py-2 pr-4">
              <mat-checkbox [checked]="columnCheckboxChecked()" (change)="updateColumnSelection()" />
            </mat-header-cell>
          </ng-container>
        }

        <mat-header-row *matHeaderRowDef="columnIds()" class="bg-gray-300" />
      </mat-table>

      @if (dataSource().filteredData.length !== 0) {
        <cdk-virtual-scroll-viewport
          #scroll_viewport
          [itemSize]="itemSize()"
          [minBufferPx]="minBufferPx()"
          [maxBufferPx]="maxBufferPx()"
          class="flex-grow">
          <div
            *cdkVirtualFor="let item of dataSource(); let even = even; let last = last; trackBy: trackByItemId()"
            [class]="last ? '' : 'border-b border-b-slate-300'"
            [ngStyle]="dataDependentStyle()"
            [style.backgroundColor]="rowBackgroundColor(item, even)"
            class="items-center">
            @if (dataDependentStyle() !== undefined) {
              @let _rowSelected = itemIsSelectedSignal(item);

              @if (_hasRowSelection && _selectionColumnIsBefore) {
                <mat-checkbox
                  class="mx-auto pl-2"
                  (change)="updateItemSelectionState(item, $event.checked)"
                  [checked]="_rowSelected()" />
              }

              @for (columnDefinition of columnDefinitions(); track $index) {
                @let _tooltip = getTooltip(item, columnDefinition);

                <span
                  [matTooltip]="_tooltip"
                  matTooltipPosition="above"
                  [class.italic]="_tooltip.length > 0"
                  class="w-full text-center text-sm"
                  >{{
                    columnDefinition.formattedPropertyValue(item) + (_tooltip.length > 0 ? '*' : '') || 'Loading...'
                  }}</span
                >
              }

              @if (_hasRowSelection && !_selectionColumnIsBefore) {
                <mat-checkbox
                  class="mx-auto"
                  (change)="updateItemSelectionState(item, $event.checked)"
                  [checked]="_rowSelected()" />
              }
            }
          </div>
        </cdk-virtual-scroll-viewport>
      } @else {
        <div class="flex flex-grow items-center justify-center bg-gray-100">
          <span class="text-xl">{{ emptyDataMessage() }}</span>
        </div>
      }
    </div>
  </div>
</div>
