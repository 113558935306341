import { Guid } from '@astrion-webtools/graph';
import { mapSensorFromDto, Sensor, SensorDto } from '@features/sensors/shared/interfaces/sensor.interface';

interface EquipmentProperties {
  name: string;
  equipmentType: string | null;
  model: string | null;
  description: string | null;
}

export type EquipmentId = Guid;

export interface EquipmentDto extends EquipmentProperties {
  id: EquipmentId;
  sensors: SensorDto[];
}

export type EquipmentPayload = EquipmentProperties; // extends if we want to add new properties

export interface Equipment extends EquipmentProperties {
  id: EquipmentId;
  sensors: Sensor[];
}

export const mapEquipmentFromDto = (dto: EquipmentDto): Equipment => ({
  ...dto,
  sensors: dto.sensors?.map(s => mapSensorFromDto(s)) || [],
});
