@let _hasData = hasData();
@let _expanded = expanded();

<mat-accordion>
  <mat-expansion-panel #panel class="w-full" [expanded]="_expanded" hideToggle disabled>
    <mat-expansion-panel-header class="mx-4 flex cursor-default flex-row items-center gap-2 py-2 text-sm">
      <mat-panel-title class="!text-black">{{ name() }}</mat-panel-title>
      <span class="flex flex-grow flex-row items-center">
        <ng-content select="[headers]" />
      </span>
      <span class="flex flex-none flex-row items-center">
        @if (showSettings()) {
          <button
            mat-icon-button
            class="graph-action-button"
            (click)="onSettings($event)"
            [disabled]="!_hasData || !_expanded">
            <mat-icon>settings</mat-icon>
          </button>
        }
        @if (showDownload()) {
          <button
            mat-icon-button
            class="graph-action-button"
            (click)="onDownload($event)"
            [disabled]="downloadDisabled() || !_hasData || !graph()">
            <mat-icon>download</mat-icon>
          </button>
        }
        @if (showDelete()) {
          <button mat-icon-button class="graph-action-button" (click)="onDelete($event)" [disabled]="!_hasData">
            <mat-icon>delete</mat-icon>
          </button>
        }
        <button mat-icon-button class="graph-action-button" (click)="onTogglePanel($event)">
          <mat-icon>{{ _expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
        </button>
      </span>
    </mat-expansion-panel-header>
    <mat-divider />
    @if (legend(); as legend) {
      <app-graph-wrapper-base-legend [legend]="legend" />
    }
    <mat-drawer-container [style.height]="graphHeight()">
      <mat-drawer-content class="relative overflow-clip">
        <ng-content select="[graph]" />
        @if (!_hasData) {
          <div class="absolue inset-0 flex h-full w-full flex-row content-center items-center justify-center gap-2">
            <mat-icon class="align-middle">block</mat-icon><span>No content</span>
          </div>
        }
      </mat-drawer-content>
      <mat-drawer #drawer position="end" mode="side">
        <div #settingsWrapper class="h-full w-full">
          <ng-content select="[settings]" />
        </div>
      </mat-drawer>
    </mat-drawer-container>
  </mat-expansion-panel>
</mat-accordion>
