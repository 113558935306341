<div class="flex w-full flex-col">
  @if (fusion() !== undefined) {
    <app-harmonics-graph
      class="w-full"
      [fusion]="fusion()!"
      [harmonicSeries]="coloredHarmonicSeries()"
      [seriesSelectionFilter]="seriesSelectionFilter()" />
  }

  <app-fixed-size-item-virtual-scroll-table
    class="mx-8 mt-5 h-[60vh]"
    [data]="coloredHarmonicSeries()"
    [options]="tableOptions"
    (selectionChanged)="selectedSeries.set($event)" />
</div>
