<app-signal-report-display
  [signalId]="signalId()"
  [reportTitle]="reportTitle()"
  [equipment]="equipment()"
  [sensor]="sensor()"
  [signalLoadingState]="loadingState()"
  [pages]="pages()"
  (downloadClicked)="downloadSignal()"
  (homeClicked)="navigateToRoot()"
  (equipmentClicked)="navigateToEquipment($event)"
  (sensorClicked)="navigateToSensor($event)" />
