@let _expanded = expanded();

<div class="primary flex h-full flex-col gap-3 p-3 pt-3">
  @if (_expanded) {
    <div class="flex flex-none flex-row justify-between py-2 pl-4 pr-2">
      <app-home-button [expanded]="_expanded" />
      <button
        mat-icon-button
        matTooltip="Show less information"
        matTooltipPosition="after"
        (click)="expansionButtonToggled.emit()">
        <mat-icon class="primary rotate-90"> unfold_less </mat-icon>
      </button>
    </div>
  } @else {
    <div class="flex flex-none flex-col gap-3 px-2 pt-2">
      <app-home-button [expanded]="_expanded" />
      <div mat-icon-button class="mt-3 cursor-pointer items-center text-center">
        <mat-icon
          matTooltip="Show more information"
          matTooltipPosition="after"
          class="primary rotate-90"
          (click)="expansionButtonToggled.emit()"
          >unfold_more</mat-icon
        >
      </div>
    </div>
  }
  <div class="flex flex-1 flex-col" [class.gap-3]="!_expanded">
    <app-project-selection class="flex-none" [expanded]="_expanded" />
    <app-navigation-list class="flex-1" [expanded]="_expanded" />
  </div>
  @if (_expanded) {
    <mat-divider [inset]="true" class="border-outline-variant ml-3 mr-4 flex-none" />
  }
  <app-account-menu class="flex-none" [expanded]="_expanded" />
</div>
