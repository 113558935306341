@let _equipment = equipment();

<div class="flex h-full w-full flex-col content-stretch items-stretch p-0">
  <app-path-bar [equipment]="_equipment" (homeClicked)="homeClicked()" />
  @if (_equipment) {
    <app-equipment-overview [equipment]="_equipment" />
    <app-equipment
      class="flex-grow"
      [isLoading]="isLoading()"
      [canEdit]="canEdit()"
      [equipment]="_equipment"
      (refreshClicked)="refresh()"
      (sensorClicked)="sensorClicked($event)" />
  } @else {
    <app-entity-not-found entityName="equipment" [notFound]="true" />
  }
</div>
