@let _icon = icon();

<mat-card appearance="outlined" class="w-[281px]">
  <mat-card-content class="p-[12px]"
    ><div class="m3-body-large flex select-none flex-col">
      <span>{{ title() }}</span>
      <div class="mt-1 flex select-none flex-row justify-between">
        <ng-content />
        @if (_icon) {
          <mat-icon class="fg-primary-30">{{ icon() }}</mat-icon>
        }
      </div>
    </div></mat-card-content
  >
</mat-card>
