import { ChangeDetectionStrategy, Component, computed, input, output, viewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GlGraphComponent, GraphInput, Line, Scaling, SegmentedLine } from '@astrion-webtools/graph';
import { GraphWrapperBaseComponent } from '@components/graph-wrapper-base/graph-wrapper-base.component';

@Component({
  selector: 'app-vertical-graphs-wrapper',
  standalone: true,
  imports: [GraphWrapperBaseComponent, GlGraphComponent],
  templateUrl: './vertical-graphs-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerticalGraphsWrapperComponent {
  wrapperId = crypto.randomUUID();

  name = input.required<string>();
  graphsData = input.required<GraphInput[] | undefined>();
  xTitle = input<string | undefined>(undefined);
  yTitle = input<string | undefined>(undefined);
  xMeanTicks = input<number | undefined>(undefined);
  yMeanTicks = input<number | undefined>(undefined);
  graphHeight = input<string>('70vh');
  lines = input<(SegmentedLine | Line)[][] | undefined>(undefined);
  yScale = input<Scaling | undefined>(undefined);
  color = input<string | string[]>('#408fff');
  xAxisDataIsTimestamps = input(false);

  graphsRefs = viewChildren<GlGraphComponent>('graph');

  constructor(private exportDialog: MatDialog) {}

  graphs = computed(() => {
    const graphsData = this.graphsData() ?? [];
    const lines = this.lines();
    let colors = this.color();
    if (lines !== undefined && lines.length !== graphsData.length) {
      throw new Error("Lines and data don't have the same size.");
    }
    if (Array.isArray(colors)) {
      if (colors.length !== graphsData.length) {
        throw new Error("Color and data don't have the same size.");
      }
    } else {
      colors = graphsData.map(() => colors as string);
    }
    return graphsData.map((data, i) => ({
      id: `${this.wrapperId}-${i}`,
      data,
      lines: lines ? lines[i] : undefined,
      color: colors[i],
    }));
  });
  hasData = computed(() => this.graphs().length > 0);
  oneGraph = computed(() => this.graphs().length == 1);

  mainGraph = computed(() => this.graphsRefs()[0] ?? undefined);

  showDelete = input(false);

  delete = output();
}
