@if (loaded() || loading()) {
  <mat-drawer-container class="h-full w-full bg-slate-50 p-0">
    <mat-drawer mode="side" opened class="w-72">
      <app-report-navigation [pages]="pages()" [scrolledAnchor]="scrolledAnchor()" />
    </mat-drawer>
    <mat-drawer-content class="flex flex-col overflow-x-hidden">
      <app-path-bar
        [equipment]="equipment()"
        [sensor]="sensor()"
        [filename]="reportTitle()"
        class="flex-initial"
        (equipmentClicked)="equipmentClicked.emit($event)"
        (sensorClicked)="sensorClicked.emit($event)"
        (homeClicked)="homeClicked.emit()">
        <button mat-icon-button matTooltip="Refresh report data" class="primary-button" (click)="onRefreshClicked()">
          <mat-icon>refresh</mat-icon>
        </button>
        <button
          mat-icon-button
          matTooltip="Download signal"
          aria-label="Button that allows to download the file in the current report."
          [disabled]="loading()"
          class="primary-button"
          (click)="downloadClicked.emit()">
          <mat-icon>download_file</mat-icon>
        </button>
      </app-path-bar>
      <app-report-content-container
        class="mb-10 flex-grow"
        [loading]="loading()"
        (scrolledAnchor)="scrolledAnchor.set($event)" />
    </mat-drawer-content>
  </mat-drawer-container>
} @else {
  <app-entity-not-found entityName="signal" [error]="error()" [notFound]="notFound()" />
}
