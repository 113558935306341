<div class="flex w-full flex-col">
  <app-graph-wrapper
    name="Fusion"
    [data]="fusionData()"
    xTitle="Frequency (Hz)"
    [yTitle]="yTitle()"
    [yScale]="yScale()"
    [lines]="fusionPeakLines()"
    [syncWithVerticalLines]="true"
    [showDelete]="false">
    <app-peaks-graph-interactive-legend
      class="mx-8"
      [dbScale]="dbScale()"
      (dbScaleChange)="dbScale.set($event)"
      [curveOptions]="curveOptions"
      [peaks]="fusionPeaks()"
      (peaksPredicateChanged)="peaksFilter.set($event)"
      settings />
  </app-graph-wrapper>

  <app-peaks-table
    class="mx-8 mt-5 h-[60vh]"
    [peaks]="fusionPeaks()"
    [filterPredicate]="peaksFilter()"
    [additionalColumns]="peaksTableAdditionalColumns()" />
</div>
