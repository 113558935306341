import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { Router } from '@angular/router';
import { PathBarComponent } from '@components/path-bar/path-bar.component';
import { EquipmentsActions } from '@features/equipments/shared/store/equipments.actions';
import { equipmentsFeature } from '@features/equipments/shared/store/equipments.feature';
import { Store } from '@ngrx/store';
import { EquipmentComponent } from '../../components/equipment/equipment.component';
import { EquipmentOverviewComponent } from '@features/equipments/components/equipment-overview/equipment-overview.component';
import { authFeature } from '@features/auth/shared/store/auth.feature';
import { Sensor } from '@features/sensors/shared/interfaces/sensor.interface';
import { EntityNotFoundComponent } from '@components/entity-not-found/entity-not-found.component';

@Component({
  selector: 'app-equipment-view',
  templateUrl: './equipment-view.component.html',
  standalone: true,
  imports: [PathBarComponent, EquipmentComponent, EquipmentOverviewComponent, EntityNotFoundComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [],
})
export class EquipmentViewComponent {
  public canEdit = this.store.selectSignal(authFeature.selectCanEdit);
  id = this.store.selectSignal(equipmentsFeature.selectCurrentEquipmentId);
  equipment = this.store.selectSignal(equipmentsFeature.selectCurrentEquipment);
  isLoading = computed(() => this.sensorsLoading());

  private sensorsLoading = this.store.selectSignal(equipmentsFeature.selectIsLoading);

  constructor(
    private store: Store,
    private router: Router
  ) {
    this.store.dispatch(EquipmentsActions.refreshIfNotInit());
  }

  homeClicked() {
    this.router.navigateByUrl(`/operation`);
  }

  refresh() {
    this.store.dispatch(EquipmentsActions.equipmentsFetchRequested());
  }

  sensorClicked(sensor: Sensor) {
    this.router.navigateByUrl(`/sensor/${sensor.id}`);
  }
}
