import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  CreateSensorDialogComponent,
  CreateSensorDialogData,
} from '@features/sensors/components/create-sensor-dialog/create-sensor-dialog.component';
import { Store } from '@ngrx/store';
import { lastValueFrom } from 'rxjs';

import { SensorsActions } from '../store/sensors.actions';

@Injectable()
export class CreateSensorDialogService {
  constructor(
    private dialog: MatDialog,
    private store: Store
  ) {}

  async open(data: CreateSensorDialogData): Promise<void> {
    const sensor = await lastValueFrom(
      this.dialog
        .open(CreateSensorDialogComponent, {
          data,
          role: 'dialog',
          autoFocus: '.focused',
          width: '560px',
        })
        .afterClosed()
    );
    if (sensor) {
      this.store.dispatch(SensorsActions.sensorCreateRequested({ sensor }));
    }
  }
}
