import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { lastValueFrom } from 'rxjs';
import { EquipmentsActions } from '../store/equipments.actions';
import { CreateEquipmentDialogComponent } from '@features/equipments/components/create-equipment-dialog/create-equipment-dialog.component';

@Injectable()
export class CreateEquipmentDialogService {
  constructor(
    private dialog: MatDialog,
    private store: Store
  ) {}

  async open(): Promise<void> {
    const equipment = await lastValueFrom(
      this.dialog
        .open(CreateEquipmentDialogComponent, {
          role: 'dialog',
          autoFocus: '.focused',
          width: '560px',
        })
        .afterClosed()
    );
    if (equipment) {
      this.store.dispatch(EquipmentsActions.equipmentCreateRequested({ equipment }));
    }
  }
}
