@let _timeSaturation = timeSaturation();
@let _shannonTest = shannonTest();
@let _stationarity = stationarity();

<div class="mb-6 flex w-full flex-col gap-8">
  <div class="flex flex-initial flex-col" [appReportAnchor]="REPORT_NAMES.dataValidation.anchors.timeSaturation">
    <h3 appReportAnchorTitle>Time saturation</h3>
    @if (ready(_timeSaturation) && samplesId()) {
      <app-time-saturation [timeSaturation]="_timeSaturation.data!" [samplesId]="samplesId()!" />
    } @else {
      <app-result-unavailable [loadableData]="_timeSaturation" class="h-32 w-full" />
    }
  </div>
  <div class="flex flex-initial flex-col" [appReportAnchor]="REPORT_NAMES.dataValidation.anchors.shannonTest">
    <h3 appReportAnchorTitle>Shannon test</h3>
    @if (ready(_shannonTest)) {
      <app-shannon-sampling-test [shannonTest]="_shannonTest.data!" />
    } @else {
      <app-result-unavailable [loadableData]="_shannonTest" class="h-32 w-full" />
    }
  </div>
  <div class="flex flex-initial flex-col" [appReportAnchor]="REPORT_NAMES.dataValidation.anchors.stationarity">
    <h3 appReportAnchorTitle>Stationarity</h3>
    @if (ready(_stationarity)) {
      <app-stationarity [stationarity]="_stationarity.data!" />
    } @else {
      <app-result-unavailable [loadableData]="_stationarity" class="h-32 w-full" />
    }
  </div>
</div>
