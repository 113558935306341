import { Component, computed, input } from '@angular/core';
import { OverviewCardComponent } from '@components/overview-card/overview-card.component';
import { Equipment } from '@features/equipments/shared/interfaces/equipment.interface';

@Component({
  selector: 'app-equipment-overview',
  standalone: true,
  imports: [OverviewCardComponent],
  templateUrl: './equipment-overview.component.html',
  styles: ``,
})
export class EquipmentOverviewComponent {
  equipment = input.required<Equipment>();
  sensorsCount = computed(() => this.equipment().sensors.length);
}
