<div class="flex w-full flex-col items-center gap-2 px-[2%]">
  <app-status-banner class="w-full flex-none" [status]="timeSaturation().status" [message]="statusMessage()" />

  <app-graph-wrapper
    class="w-full"
    name="Time saturation"
    xTitle="Time (ms)"
    yTitle="Ampltitude"
    [data]="data()"
    [highlightIndices]="saturationIndices()"
    [showDelete]="false"
    [showLegend]="false"
    [showDownload]="false" />
</div>
