@let _showingAll = showingAll();

<app-vertical-graphs-wrapper
  name="Spectrums"
  [graphsData]="currentCyclesData()"
  [graphHeight]="graphHeight()"
  [yTitle]="yTitle()"
  [yMeanTicks]="yMeanTicks()"
  [yScale]="yScale()"
  [lines]="currentCyclesLines()">
  <div headers>
    @if (cycles().length > 1) {
      <div class="flex flex-row justify-center gap-4 px-8 py-4">
        <mat-slide-toggle class="my-auto" [checked]="_showingAll" (change)="showingAllChanged($event)">
          Show all
        </mat-slide-toggle>

        <mat-button-toggle-group
          class="h-[32px] items-center"
          name="cycles"
          [disabled]="_showingAll"
          [value]="activeCycleIndex()"
          (valueChange)="activeCycleIndex.set($event)">
          @for (cycle of cycles(); track cycle.index) {
            <mat-button-toggle [value]="cycle.index">Cycle {{ cycle.index + 1 }}</mat-button-toggle>
          }
        </mat-button-toggle-group>
      </div>
    }
  </div>
  <app-peaks-graph-interactive-legend
    class="mx-8"
    [(dbScale)]="dbScale"
    [curveOptions]="curveOptions"
    [peaks]="_showingAll ? [] : currentCyclePeaks()"
    (peaksPredicateChanged)="peaksFilter.set($event)"
    settings />
</app-vertical-graphs-wrapper>

<div class="flex w-full flex-col">
  @if (!_showingAll) {
    <app-peaks-table class="mx-8 mt-5 h-[60vh]" [peaks]="currentCyclePeaks()" [filterPredicate]="peaksFilter()" />
  }

  @if (displayAdditionalData()) {
    <app-estimation-arrays
      [cycleIndex]="activeCycleIndex()"
      [cycleSpectrumData]="currentCycleSpectrumData()"
      [cycleNoiseData]="currentCycleNoiseData()" />
  }
</div>
