@let _sensorId = sensorSummary().id;

<div class="flex h-full w-full flex-col">
  <span class="m3-headline-small mt-2 flex-none select-none">Sensor Overview</span>

  <div class="mt-4 flex flex-row">
    <app-overview-card title="Number of signals" icon="stacked_line_chart">
      <div class="m3-headline-large">{{ signalCount() }}</div>
    </app-overview-card>
  </div>

  <div class="mt-2 flex flex-row justify-center">
    <mat-nav-list class="horizontal-nav-list flex rounded-3xl py-0 text-center font-bold">
      <a
        mat-list-item
        *ngFor="let tab of tabs"
        [class.bg-nav-selected]="isSelected(tab.id)()"
        class="min-w-36"
        [routerLink]="[]"
        [fragment]="tab.fragment"
        >{{ tab.name }}</a
      >
    </mat-nav-list>
  </div>

  <div class="h-full w-full flex-grow">
    @if (_sensorId) {
      <app-sensor-signals
        id="Signals"
        [sensorId]="_sensorId"
        [hidden]="!signalsTabSelected()"
        [loading]="loading()"
        (navigateToGraph)="navigateToGraph()"
        (navigateToReport)="navigateToReport($event)" />

      <app-sensor-trajectories
        id="Trajectories"
        class="h-full w-full"
        [sensorId]="_sensorId"
        [hidden]="!trajectoriesTabSelected()"
        (navigateToGraph)="navigateToGraph()" />

      <app-sensor-graphs
        id="Graph"
        [sensorId]="_sensorId"
        [hidden]="!graphsTabSelected()"
        class="h-full w-full"
        name="Sensor graph" />
    }
  </div>
</div>
