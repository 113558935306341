<h1 mat-dialog-title class="select-none">New equipment</h1>
<form [formGroup]="equipmentForm" (ngSubmit)="onSubmit()">
  <mat-dialog-content class="flex flex-col">
    <ng-template #formfield let-field="field" let-label="label" let-required="required">
      <div class="flex flex-row items-baseline">
        <mat-label class="w-[100px] select-none">{{ label }}{{ required ? ' *' : '' }}</mat-label
        ><mat-form-field appearance="outline" class="w-[400px]"
          ><input class="focused" type="text" matInput [formControlName]="field" />
          @if (this.equipmentForm.get(field)?.hasError('required')) {
            <mat-error class="select-none">{{ label }} is required</mat-error>
          }
        </mat-form-field>
      </div>
    </ng-template>
    <ng-container *ngTemplateOutlet="formfield; context: { field: 'name', label: 'Name', required: true }" />
    <ng-container *ngTemplateOutlet="formfield; context: { field: 'description', label: 'Description' }" />
    <ng-container *ngTemplateOutlet="formfield; context: { field: 'equipmentType', label: 'Type' }" />
    <ng-container *ngTemplateOutlet="formfield; context: { field: 'model', label: 'Model' }" />
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button class="secondary-button" mat-dialog-close>Cancel</button>
    <button mat-button class="secondary-button" [disabled]="!equipmentForm.valid">Create</button>
  </mat-dialog-actions>
</form>
