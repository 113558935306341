@let _equipment = equipment();

<span class="m3-headline-small flex-none select-none">Equipment overview</span>
<div class="mt-4 flex flex-row">
  <app-overview-card title="Information" class="mr-2">
    <div class="text-sm">
      <div id="codename">Code name: {{ _equipment.name }}</div>
      <div id="type">Type: {{ _equipment.equipmentType || '-' }}</div>
    </div>
    <div id="model" class="text-sm">
      <div>Model: {{ _equipment.model || '-' }}</div>
    </div>
  </app-overview-card>
  <app-overview-card title="Number of sensors" icon="sensors" class="ml-2">
    <div data-testid="sensors-count" class="m3-headline-large">{{ sensorsCount() }}</div>
  </app-overview-card>
</div>
