import { Injectable } from '@angular/core';
import { RealtimeRegistrationInterface } from '@features/realtime/shared/interfaces/realtime-registration-service';
import { RealtimeClientService } from '@features/realtime/shared/services/realtime-client.service';
import { Store } from '@ngrx/store';

import { mapSensorFromDto } from '../interfaces/sensor.interface';
import { SensorsActions } from '../store/sensors.actions';
import { SensorCreatedMessage, SensorDeletedMessage } from './interfaces';
import { EquipmentId } from '@features/equipments/shared/interfaces/equipment.interface';

@Injectable()
export class SensorsRealtimeService implements RealtimeRegistrationInterface {
  constructor(private store: Store) {}

  registerMessageHandlers(realtimeClient: RealtimeClientService): void {
    realtimeClient.registerMessageHandlers([{ messageType: 'SensorCreated', callback: this.sensorCreatedHandler }]);
    realtimeClient.registerMessageHandlers([{ messageType: 'SensorDeleted', callback: this.sensorDeletedHandler }]);
  }

  sensorCreatedHandler = (message: unknown): void => {
    const sensorCreatedMessage = message as SensorCreatedMessage;
    this.store.dispatch(
      SensorsActions.sensorCreated({
        sensor: mapSensorFromDto({ ...sensorCreatedMessage, signalsCount: 0 }),
      })
    );
  };

  sensorDeletedHandler = (message: unknown): void => {
    const sensorDeletedMessage = message as SensorDeletedMessage;
    this.store.dispatch(
      SensorsActions.sensorDeleted({
        id: sensorDeletedMessage.id,
        equipmentId: sensorDeletedMessage.equipmentId as EquipmentId,
      })
    );
  };
}
