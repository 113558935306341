import { createReducer, on } from '@ngrx/store';
import { LoadingState } from '@shared/interfaces/loading-state';

import { EquipmentsActions } from './equipments.actions';
import { EQUIPMENTS_INITIAL_STATE, EquipmentsState } from './equipments.state';
import { SensorsActions } from '@features/sensors/shared/store/sensors.actions';
import { SignalsActions } from '@features/signals/shared/store/signals.actions';
import { AStrionSignalUploadStatus } from '@features/signals/shared/interface/astrion-signal-upload-result.interface';

export const reducer = createReducer(
  EQUIPMENTS_INITIAL_STATE,
  on(
    EquipmentsActions.equipmentsFetchRequested,
    (state): EquipmentsState => ({ ...state, loadingState: LoadingState.Loading })
  ),
  on(
    EquipmentsActions.equipmentsFetchFailed,
    (state): EquipmentsState => ({ ...state, loadingState: LoadingState.Error })
  ),
  on(
    EquipmentsActions.equipmentsFetched,
    (state, { equipments }): EquipmentsState => ({ ...state, loadingState: LoadingState.Loaded, equipments })
  ),
  on(
    EquipmentsActions.equipmentCreated,
    (state: EquipmentsState, { equipment }): EquipmentsState =>
      state.equipments.some(s => s.id === equipment.id)
        ? state
        : { ...state, equipments: [...state.equipments, equipment] }
  ),
  on(
    EquipmentsActions.equipmentDeleted,
    (state, { id }): EquipmentsState => ({ ...state, equipments: state.equipments.filter(s => s.id !== id) })
  ),
  on(
    SensorsActions.sensorCreated,
    (state: EquipmentsState, { sensor }): EquipmentsState => ({
      ...state,
      equipments: state.equipments.map(equipment =>
        equipment.id === sensor.equipmentId
          ? {
              ...equipment,
              sensors: equipment.sensors.some(s => s.id === sensor.id)
                ? equipment.sensors
                : [...equipment.sensors, sensor],
            }
          : equipment
      ),
    })
  ),
  on(
    SensorsActions.sensorDeleted,
    (state: EquipmentsState, { id, equipmentId }): EquipmentsState => ({
      ...state,
      equipments: state.equipments.map(equipment =>
        equipment.id === equipmentId
          ? {
              ...equipment,
              sensors: equipment.sensors.filter(sensor => sensor.id !== id),
            }
          : equipment
      ),
    })
  ),
  on(
    SignalsActions.signalDeleted,
    (state: EquipmentsState, { sensorId }): EquipmentsState => ({
      ...state,
      equipments: state.equipments.map(equipment => ({
        ...equipment,
        sensors: equipment.sensors.map(sensor =>
          sensor.id === sensorId ? { ...sensor, signalsCount: sensor.signalsCount - 1 } : sensor
        ),
      })),
    })
  ),
  on(SignalsActions.signalsUploaded, (state, { uploads, sensorId }) => {
    const successfulUploadsCount = uploads.filter(upload => upload.status === AStrionSignalUploadStatus.Success).length;
    return {
      ...state,
      equipments: state.equipments.map(equipment => ({
        ...equipment,
        sensors: equipment.sensors.map(sensor =>
          sensor.id === sensorId ? { ...sensor, signalsCount: sensor.signalsCount + successfulUploadsCount } : sensor
        ),
      })),
    };
  })
);
