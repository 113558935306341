export enum ValidationStepStatus {
  Unknown,
  Success,
  Warning,
  Failure,
  Neutral,
}

export enum ValidationStepStatusDto {
  Unknown,
  Success,
  Warning,
  Failure,
  Neutral,
}

const statusColor: Record<ValidationStepStatus, string> = {
  [ValidationStepStatus.Unknown]: '#e0e0e0',
  [ValidationStepStatus.Success]: 'bg-green-700',
  [ValidationStepStatus.Warning]: 'bg-yellow-300',
  [ValidationStepStatus.Failure]: 'bg-red-700',
  [ValidationStepStatus.Neutral]: 'bg-blue-400',
};

const statusTextColor: Record<ValidationStepStatus, string> = {
  [ValidationStepStatus.Unknown]: 'text-white',
  [ValidationStepStatus.Success]: 'text-white',
  [ValidationStepStatus.Warning]: 'text-grey',
  [ValidationStepStatus.Failure]: 'text-white',
  [ValidationStepStatus.Neutral]: 'text-white',
};

export function getValidationStepStatusColor(status: ValidationStepStatus | undefined) {
  return statusColor[status ?? ValidationStepStatus.Unknown];
}

export function getValidationStepStatusTextColor(status: ValidationStepStatus | undefined) {
  return statusTextColor[status ?? ValidationStepStatus.Unknown];
}
