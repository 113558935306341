import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ResultUnavailableComponent } from '@components/result-unavailable/result-unavailable.component';
import { CyclesComponent } from '@features/peak-identification/components/cycles/cycles.component';
import { FusionComponent } from '@features/peak-identification/components/fusion/fusion.component';
import { HarmonicSeriesComponent } from '@features/peak-identification/components/harmonic-series/harmonic-series.component';
import { peakIdentificationFeature } from '@features/peak-identification/shared/store/peak-identification.feature';
import { ReportPageBaseComponent } from '@features/signal-report/components/report-page-base/report-page-base';
import { ReportAnchorTitleDirective } from '@features/signal-report/shared/directives/report-anchor-title.directive';
import {
  computationCompleted,
  ComputationStep,
} from '@features/signal-report/shared/interface/computation-step-progress';
import { REPORT_NAMES } from '@features/signal-report/shared/store/report-pages';
import { Store } from '@ngrx/store';
import { ReportAnchorDirective } from '@tools/directives/report-anchor.directive';

@Component({
  selector: 'app-report-peak-identification',
  standalone: true,
  imports: [
    CyclesComponent,
    FusionComponent,
    HarmonicSeriesComponent,
    ReportAnchorDirective,
    ReportAnchorTitleDirective,
    ResultUnavailableComponent,
  ],
  templateUrl: './report-peak-identification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportPeakIdentificationComponent extends ReportPageBaseComponent {
  REPORT_NAMES = REPORT_NAMES;

  public cycles = this.store.selectSignal(peakIdentificationFeature.selectCycles);
  public fusion = this.store.selectSignal(peakIdentificationFeature.selectFusion);
  public harmonicSeries = this.store.selectSignal(peakIdentificationFeature.selectHarmonicSeries);

  public ready = (step: ComputationStep): boolean => computationCompleted(step);

  constructor(private store: Store) {
    super();
  }
}
