import { CommonModule } from '@angular/common';
import { Component, computed, input, output, viewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ContextMenuComponent } from '@components/context-menu/context-menu.component';
import { ScrollableBorderedContainerComponent } from '@components/scrollable-bordered-container/scrollable-bordered-container.component';
import { Equipment } from '@features/equipments/shared/interfaces/equipment.interface';
import { CreateEquipmentDialogService } from '@features/equipments/shared/services/create-equipment-dialog.service';
import { EquipmentsActions } from '@features/equipments/shared/store/equipments.actions';
import { MaterialModule } from '@modules/material.module';
import { Store } from '@ngrx/store';
import { DialogService } from '@services/dialog.service';

@Component({
  selector: 'app-operation',
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    MatProgressBarModule,
    MatIconModule,
    MatToolbarModule,
    ContextMenuComponent,
    ScrollableBorderedContainerComponent,
  ],
  providers: [DialogService, CreateEquipmentDialogService],
  templateUrl: './operation.component.html',
  styles: ``,
})
export class OperationComponent {
  private _sort = viewChild.required(MatSort);
  rightTrigger = viewChild<MatMenuTrigger>('rightTrigger');

  equipments = input.required<Equipment[]>();
  isLoading = input.required<boolean>();
  canEdit = input.required<boolean>();

  refreshClicked = output();
  equipmentClicked = output<Equipment>();

  public columns = ['name', 'description', 'sensorsCount', 'button'];
  datasource = computed(() => {
    const sort = this._sort();
    const items = this.equipments();
    const datasource = new MatTableDataSource(items);
    datasource.sort = sort;
    return datasource;
  });

  public _menuOpened = false;
  public rightClickMenuPosition = { x: '0', y: '0' };

  constructor(
    private createEquipmentDialogService: CreateEquipmentDialogService,
    private dialogService: DialogService,
    private store: Store
  ) {}

  public numberOfSensors(equipment: Equipment) {
    const count = equipment.sensors.length;
    if (count > 0) {
      return `${count}`;
    } else {
      return '-';
    }
  }

  public onClick(equipment: Equipment) {
    this.equipmentClicked.emit(equipment);
  }

  public onRightClick(event: MouseEvent, equipment: Equipment) {
    event.preventDefault();
    event.stopPropagation();
    this.rightClickMenuPosition.x = event.clientX + 'px';
    this.rightClickMenuPosition.y = event.clientY + 'px';
    this.rightTrigger()!.menuData = { item: equipment };
    this.rightTrigger()!.openMenu();
  }

  async delete(equipment: Equipment) {
    if (
      await this.dialogService.confirm({
        title: 'Delete equipment',
        descriptions: [
          'Deleting the equipment will delete all its sensors and all their signals.',
          'This action is IRREVERSIBLE. Are you sure?',
        ],
      })
    ) {
      this.store.dispatch(EquipmentsActions.equipmentDeleteRequested({ id: equipment.id }));
    }
  }

  async openCreateEquipmentDialog() {
    this.createEquipmentDialogService.open();
  }

  openInNew(item: Equipment) {
    window.open(`/equipment/${item.id}`, '_blank');
  }

  public menuOpened() {
    this._menuOpened = true;
  }

  public menuClosed() {
    this._menuOpened = false;
  }
}
