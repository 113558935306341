import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  FilterDialogComponent,
  FilterDialogData,
  FilterModel,
} from '@components/filters/filter-dialog/filter-dialog.component';
import {
  OptionFilterDialogComponent,
  OptionFilterDialogData,
  OptionFilterModel,
} from '@components/filters/option-filter-dialog/option-filter-dialog.component';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class FilterDialogService {
  constructor(private dialog: MatDialog) {}

  async open(data: FilterDialogData, clientX: number, clientY: number): Promise<FilterModel | undefined> {
    return await lastValueFrom(
      this.dialog
        .open(FilterDialogComponent, {
          data,
          position: { top: `${clientY}px`, left: `${clientX + 10}px` },
          role: 'dialog',
          autoFocus: '.focused',
        })
        .afterClosed()
    );
  }

  async openOptionFilter(
    data: OptionFilterDialogData,
    clientX: number,
    clientY: number
  ): Promise<OptionFilterModel | undefined> {
    return await lastValueFrom(
      this.dialog
        .open(OptionFilterDialogComponent, {
          data,
          position: { top: `${clientY}px`, left: `${clientX + 10}px` },
          role: 'dialog',
          autoFocus: '.focused',
        })
        .afterClosed()
    );
  }
}
