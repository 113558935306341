import { Component, computed, ElementRef, input, output, Signal, signal, viewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatDrawer } from '@angular/material/sidenav';
import { MaterialModule } from '@modules/material.module';
import { CurveLegend } from './graph-wrapper-base.interface';
import { GraphWrapperBaseLegendComponent } from './graph-wrapper-base-legend/graph-wrapper-base-legend.component';
import { CsvExportOptions, GlGraphComponent } from '@astrion-webtools/graph';
import { firstValueFrom } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { GraphWrapperBaseExportDialogComponent } from './graph-wrapper-base-export-dialog/graph-wrapper-base-export-dialog.component';
import { download } from '@tools/utilities/download';

@Component({
  selector: 'app-graph-wrapper-base',
  standalone: true,
  imports: [MaterialModule, GraphWrapperBaseLegendComponent],
  templateUrl: './graph-wrapper-base.component.html',
})
export class GraphWrapperBaseComponent {
  name = input.required<string>();
  xTitle = input.required<string>();
  graphHeight = input<string | undefined>(undefined);
  hasData = input.required<boolean>();
  graph = input.required<GlGraphComponent | undefined>();
  showDownload = input(true);
  showLegend = input(false);
  showDelete = input(false);

  downloadDisabled = input(false);
  legend = input<CurveLegend[] | undefined>(undefined);

  delete = output();

  drawer = viewChild.required<MatDrawer>('drawer');
  panel = viewChild.required<MatExpansionPanel>('panel');
  settingsWrapper: Signal<ElementRef<HTMLDivElement>> = viewChild.required('settingsWrapper', {
    read: ElementRef<HTMLDivElement>,
  });

  showSettings = computed(() => this.settingsWrapper().nativeElement.children.length > 0);

  constructor(private exportDialog: MatDialog) {}

  onSettings(event: Event) {
    this.drawer().toggle();
    event.stopPropagation();
  }

  onDelete(event: Event) {
    this.delete.emit();
    event.stopPropagation();
  }

  onTogglePanel(event: Event) {
    this.expanded.update(e => !e);
    event.stopPropagation();
  }

  onDownload = async (event: Event) => {
    event.stopPropagation();
    const graph = this.graph();
    if (!graph) {
      return;
    }
    const options: CsvExportOptions = await firstValueFrom(
      this.exportDialog
        .open(GraphWrapperBaseExportDialogComponent, { restoreFocus: false, data: { xTitle: this.xTitle() } })
        .afterClosed()
    );
    if (options) {
      const csvText = await graph.exportCsv(options);
      download('export.csv', csvText);
    }
  };

  expanded = signal(true);
}
