import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { EquipmentPayload } from '@features/equipments/shared/interfaces/equipment.interface';
import { MaterialModule } from '@modules/material.module';

@Component({
  selector: 'app-create-equipment-dialog',
  standalone: true,
  imports: [CommonModule, MaterialModule, ReactiveFormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './create-equipment-dialog.component.html',
  styles: ``,
})
export class CreateEquipmentDialogComponent {
  equipmentForm = new FormGroup({
    name: new FormControl<string | null>(null, Validators.required),
    equipmentType: new FormControl<string | null>(null),
    model: new FormControl<string | null>(null),
    description: new FormControl<string | null>(null),
  });

  constructor(private dialogRef: MatDialogRef<CreateEquipmentDialogComponent>) {}

  async onSubmit() {
    const payload: EquipmentPayload = {
      name: this.equipmentForm.value.name!,
      equipmentType: this.equipmentForm.value.equipmentType!,
      model: this.equipmentForm.value.model!,
      description: this.equipmentForm.value.description!,
    };
    this.dialogRef.close(payload);
  }
}
