import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EquipmentId } from '@features/equipments/shared/interfaces/equipment.interface';
import { SensorPayload } from '@features/sensors/shared/interfaces/sensor.interface';
import { SensorsApiService } from '@features/sensors/shared/services/sensors-api.service';
import { MaterialModule } from '@modules/material.module';

export interface CreateSensorDialogData {
  equipmentId: EquipmentId;
}

@Component({
  selector: 'app-create-sensor-dialog',
  standalone: true,
  imports: [CommonModule, MaterialModule, ReactiveFormsModule],
  providers: [SensorsApiService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './create-sensor-dialog.component.html',
  styles: ``,
})
export class CreateSensorDialogComponent {
  sensorForm = new FormGroup({
    name: new FormControl<string | null>(null, Validators.required),
    type: new FormControl<string | null>(null, Validators.required),
    model: new FormControl<string | null>(null),
    component: new FormControl<string | null>(null),
    orientation: new FormControl<string | null>(null),
    collectPoint: new FormControl<string | null>(null),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CreateSensorDialogData,
    private dialogRef: MatDialogRef<CreateSensorDialogComponent>
  ) {}

  async onSubmit() {
    const payload: SensorPayload = {
      equipmentId: this.data.equipmentId,
      name: this.sensorForm.value.name!,
      sensorType: this.sensorForm.value.type!,
      model: this.sensorForm.value.model!,
      component: this.sensorForm.value.component!,
      orientation: this.sensorForm.value.orientation!,
      collectPoint: this.sensorForm.value.collectPoint!,
    };
    this.dialogRef.close(payload);
  }
}
