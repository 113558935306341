<app-graph-wrapper-base
  [name]="name()"
  [hasData]="hasData()"
  [xTitle]="xTitle()"
  [showLegend]="showLegend()"
  [showDelete]="showDelete()"
  [showDownload]="showDownload()"
  [legend]="legend()"
  [graphHeight]="graphHeight()"
  (delete)="delete.emit()"
  [graph]="graph">
  <ng-content select="[headers]" headers />
  <ng-content select="[settings]" settings />
  <lib-gl-graph
    #graph
    class="absolute inset-0"
    [style.visibility]="hasData() ? 'visible' : 'hidden'"
    [data]="data()"
    [id]="graphId"
    [xTitle]="xTitle()"
    [yTitle]="yTitle()"
    [xAxisDataIsTimestamps]="xAxisDataIsTimestamps()"
    [color]="defaultColor"
    [showGroupAxis]="showGroupAxis()"
    [highlightIndices]="highlightIndices()"
    [lines]="lines()"
    [yScale]="yScale()"
    [syncWithVerticalLines]="syncWithVerticalLines()"
    [syncWithHorizontalLines]="syncWithHorizontalLines()"
    graph />
</app-graph-wrapper-base>
