import {
  AStrionSignalUploadResult,
  AStrionSignalUploadStatus,
} from '@features/signals/shared/interface/astrion-signal-upload-result.interface';
import { SignalsActions } from '@features/signals/shared/store/signals.actions';
import { createReducer, on } from '@ngrx/store';

import { SensorsActions } from './sensors.actions';
import { SENSORS_INITIAL_STATE, SensorsState } from './sensors.state';
import { EquipmentsActions } from '@features/equipments/shared/store/equipments.actions';

export const reducer = createReducer(
  SENSORS_INITIAL_STATE,
  on(
    EquipmentsActions.equipmentsFetched,
    (state, { equipments }): SensorsState => ({
      ...state,
      sensors: equipments.flatMap(e => e.sensors),
    })
  ),
  on(
    SensorsActions.sensorCreated,
    (state: SensorsState, { sensor }): SensorsState =>
      state.sensors.some(s => s.id === sensor.id) ? state : { ...state, sensors: [...state.sensors, sensor] }
  ),
  on(
    SensorsActions.sensorDeleted,
    (state, { id }): SensorsState => ({ ...state, sensors: state.sensors.filter(s => s.id !== id) })
  ),
  on(SignalsActions.signalsUploaded, (state: SensorsState, { uploads }): SensorsState => {
    const successfulUpload = (uploadResult: AStrionSignalUploadResult) =>
      uploadResult.status === AStrionSignalUploadStatus.Success && uploadResult.signal !== null;

    const inSensorUpload = (uploadResult: AStrionSignalUploadResult) =>
      state.sensors.some(s => s.id === uploadResult.signal?.sensorId);

    const newUpload = (uploadResult: AStrionSignalUploadResult) =>
      !state.uploadedSignals.includes(uploadResult.signal!.id);

    const newUploads = uploads.filter(successfulUpload).filter(inSensorUpload).filter(newUpload);

    const sensors = state.sensors.map(sensor => ({
      ...sensor,
      signalsCount: sensor.signalsCount + newUploads.filter(upload => upload.signal?.sensorId === sensor.id).length,
    }));

    return {
      ...state,
      sensors,
      uploadedSignals: [...state.uploadedSignals, ...newUploads.map(upload => upload.signal!.id)],
    };
  }),
  on(SignalsActions.signalDeleted, (state: SensorsState, { signalId, sensorId }): SensorsState => {
    if (state.deletedSignals.includes(signalId)) {
      return state;
    }
    return {
      ...state,
      sensors: state.sensors.map(sensor =>
        sensor.id === sensorId ? { ...sensor, signalsCount: sensor.signalsCount - 1 } : sensor
      ),
      deletedSignals: [...state.deletedSignals, signalId],
    };
  })
);
