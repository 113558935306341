@let _loading = isLoading();
@let _canEdit = canEdit();

<div class="mt-4 h-full w-full flex-grow">
  <div class="relative flex w-full flex-col">
    @if (_loading) {
      <mat-progress-bar class="absolute -top-1 left-0 z-50 w-full" mode="indeterminate" />
    }

    <div class="mb-1 flex w-full flex-row items-center">
      <span class="m3-headline-small flex-none select-none">Equipments</span>

      <button mat-icon-button matTooltip="Refresh equipments" (click)="refreshClicked.emit()" [disabled]="_loading">
        <mat-icon>refresh</mat-icon>
      </button>

      @if (_canEdit) {
        <button mat-icon-button matTooltip="New Equipment" (click)="openCreateEquipmentDialog()" [disabled]="_loading">
          <mat-icon>add</mat-icon>
        </button>
      }
    </div>
  </div>
  <div class="relative flex h-full w-full flex-grow select-none flex-col">
    <app-scrollable-bordered-container class="relative flex-grow" [fadeTop]="true" [fadeBottom]="true">
      <mat-table [dataSource]="datasource()" matSortActive="name" matSortDirection="asc" class="m-0 p-0" matSort>
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef class="w-[15%] flex-initial" mat-sort-header>Name</mat-header-cell>
          <mat-cell *matCellDef="let equipment" class="w-[15%] flex-initial bg-white">
            <span>
              {{ equipment.name }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
          <mat-cell *matCellDef="let equipment" class="bg-white">
            <span>
              {{ equipment.description }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-containter matColumnDef="sensorsCount">
          <mat-header-cell *matHeaderCellDef class="w-[7%] flex-initial" mat-sort-header>Sensors</mat-header-cell>
          <mat-cell *matCellDef="let equipment" class="w-[7%] flex-initial bg-white">
            {{ numberOfSensors(equipment) }}
          </mat-cell>
        </ng-containter>

        <ng-container matColumnDef="button">
          <mat-header-cell *matHeaderCellDef class="w-[75px] flex-initial rounded-tr-lg" />
          <mat-cell *matCellDef="let item" class="w-[75px] flex-initial bg-white">
            <button
              #menuTrigger="matMenuTrigger"
              [matMenuTriggerFor]="menu"
              [matMenuTriggerRestoreFocus]="false"
              mat-icon-button
              (click)="$event.stopPropagation()"
              (menuOpened)="menuOpened()"
              (menuClosed)="menuClosed()">
              <mat-icon>more_horiz</mat-icon>
              <mat-menu #menu="matMenu">
                <app-context-menu
                  [menuTrigger]="menuTrigger"
                  [allowDownload]="false"
                  [allowOpenInNew]="true"
                  [allowDelete]="_canEdit"
                  (openInNew)="openInNew(item)"
                  (delete)="delete(item)" />
              </mat-menu>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columns; sticky: true" />
        <mat-row
          *matRowDef="let equipment; columns: columns"
          class="cursor-pointer"
          matRipple
          [matRippleDisabled]="true"
          (click)="onClick(equipment)"
          (contextmenu)="onRightClick($event, equipment)" />

        <div *matNoDataRow class="flex flex-row justify-center">
          @if (!_loading) {
            <span
              >No equipment.
              @if (_canEdit) {
                You can add equipments using « + » button
              }
            </span>
          }
        </div>
      </mat-table>
    </app-scrollable-bordered-container>
  </div>
</div>

<div
  class="fixed opacity-0"
  #rightTrigger="matMenuTrigger"
  [matMenuTriggerFor]="rightClickMenu"
  [style.left]="rightClickMenuPosition.x"
  [style.top]="rightClickMenuPosition.y"
  (menuOpened)="menuOpened()"
  (menuClosed)="menuClosed()"></div>

<mat-menu #rightClickMenu="matMenu">
  <ng-template matMenuContent let-item="item">
    <app-context-menu
      [menuTrigger]="rightTrigger"
      [allowDownload]="false"
      [allowOpenInNew]="true"
      [allowDelete]="canEdit()"
      (openInNew)="openInNew(item)"
      (delete)="delete(item)" />
  </ng-template>
</mat-menu>
