import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { ResultUnavailableComponent } from '@components/result-unavailable/result-unavailable.component';
import { ReportSignalGraphComponent } from '@features/signal-overview/components/report-signal-graph/report-signal-graph.component';
import { SignalOverviewMetadataComponent } from '@features/signal-overview/components/signal-overview-metadata/signal-overview-metadata.component';
import { signalOverviewFeature } from '@features/signal-overview/shared/store/signal-overview.feature';
import { ReportPageBaseComponent } from '@features/signal-report/components/report-page-base/report-page-base';
import { ReportAnchorTitleDirective } from '@features/signal-report/shared/directives/report-anchor-title.directive';
import { REPORT_NAMES } from '@features/signal-report/shared/store/report-pages';
import { Store } from '@ngrx/store';
import { LoadableItem, loaded } from '@shared/interfaces/loading-state';
import { ReportAnchorDirective } from '@tools/directives/report-anchor.directive';

@Component({
  selector: 'app-report-overview',
  standalone: true,
  imports: [
    ReportSignalGraphComponent,
    SignalOverviewMetadataComponent,
    ReportAnchorDirective,
    ResultUnavailableComponent,
    ReportAnchorTitleDirective,
  ],
  templateUrl: './report-overview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportOverviewComponent extends ReportPageBaseComponent {
  REPORT_NAMES = REPORT_NAMES;

  public ready = (loadableItem?: LoadableItem): boolean => loadableItem !== undefined && loaded(loadableItem!);

  private loadingState = this.store.selectSignal(signalOverviewFeature.selectLoadingState);

  loadableItem = computed(() => {
    return {
      loadingState: this.loadingState(),
    };
  });

  signalBytes = this.store.selectSignal(signalOverviewFeature.selectSignalBytes);
  signalMetadata = this.store.selectSignal(signalOverviewFeature.selectSignalMetadata);

  constructor(private store: Store) {
    super();
  }
}
