import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Equipment, EquipmentId, EquipmentPayload } from '../interfaces/equipment.interface';

export const EquipmentsActions = createActionGroup({
  source: 'Equipments',
  events: {
    noop: emptyProps(),
    refreshIfNotInit: emptyProps(),

    equipmentsFetchRequestedIfNotInit: emptyProps(),
    equipmentsFetchRequested: emptyProps(),
    equipmentsFetchFailed: emptyProps(),
    equipmentsFetched: props<{ equipments: Equipment[] }>(),

    equipmentCreateRequested: props<{ equipment: EquipmentPayload }>(),
    equipmentCreated: props<{ equipment: Equipment }>(),
    equipmentCreateFailed: emptyProps(),

    equipmentDeleteRequested: props<{ id: EquipmentId }>(),
    equipmentDeleted: props<{ id: EquipmentId }>(),
    equipmentDeleteFailed: emptyProps(),
  },
});
