import { Injectable } from '@angular/core';
import { SensorSignalsActions } from '@features/sensor-signals/shared/store/sensor-signals.actions';
import { Trajectory } from '@features/sensor-trajectories/shared/interfaces/trajectory.interface';
import { SensorTrajectoriesActions } from '@features/sensor-trajectories/shared/store/sensor-trajectories.actions';
import { sensorTrajectoriesFeature } from '@features/sensor-trajectories/shared/store/sensor-trajectories.feature';
import { SensorId } from '@features/sensors/shared/interfaces/sensor.interface';
import { Store } from '@ngrx/store';

@Injectable()
export class TrajectoryService {
  constructor(private store: Store) {}

  setCurrentSensorTrackingAsDirty(sensorId: SensorId) {
    this.store.dispatch(SensorSignalsActions.flagSensorTrackingAsDirtyRequested({ sensorId }));
  }

  fetchTrajectories(sensorId: SensorId) {
    this.store.dispatch(SensorTrajectoriesActions.trajectoriesFetchRequested({ sensorId }));
  }

  selectTrajectories() {
    return this.store.selectSignal(sensorTrajectoriesFeature.selectTrajectories);
  }

  visualizeFrequency(sensorId: SensorId, trajectory: Trajectory) {
    this.store.dispatch(
      SensorTrajectoriesActions.trajectoriesFrequencyTrendVisualizeRequested({ sensorId, trajectory })
    );
  }

  visualizeEnergy(sensorId: SensorId, trajectory: Trajectory) {
    this.store.dispatch(SensorTrajectoriesActions.trajectoriesEnergyTrendVisualizeRequested({ sensorId, trajectory }));
  }
}
