@let _statusItemDescription = statusItemDescription();

<div class="flex h-full w-full flex-col">
  <div class="mb-1 flex w-full flex-row items-center">
    <div class="m3-headline-small">Sensor Trajectories</div>
    <button mat-icon-button matTooltip="Refresh trajectories" (click)="refresh()">
      <mat-icon>refresh</mat-icon>
    </button>
    @if (canRecompute()) {
      <button matTooltip="Recompute" mat-icon-button (click)="recompute()">
        <mat-icon>play_circle</mat-icon>
      </button>
    }
    <button
      mat-icon-button
      matTooltip="{{ _statusItemDescription.tooltip }}{{ sensorTrajectoriesNextComputationDate() | date: ' (HH:mm)' }}">
      <mat-icon
        class="text-center"
        [ngStyle]="{ color: _statusItemDescription.color }"
        [ngClass]="_statusItemDescription.itemClass">
        {{ _statusItemDescription.icon }}
      </mat-icon>
    </button>
    <button
      mat-flat-button
      class="ml-auto"
      data-testid="visualize-selected"
      matTooltip="Show selected data in graph"
      [disabled]="!hasSelected()"
      (click)="visualize()">
      <mat-icon>insights</mat-icon>Visualize
    </button>
  </div>
  @if (dataSource(); as dataSource) {
    <app-scrollable-bordered-container class="flex-grow" [fadeTop]="true" [fadeBottom]="true">
      <mat-table [dataSource]="dataSource" matSort matSortActive="detectionPercentage" matSortDirection="desc">
        <ng-container matColumnDef="name">
          <mat-header-cell
            *matHeaderCellDef
            data-testid="name-column"
            class="surface border-outline-variant grow-0 basis-48 rounded-tl-lg"
            ><div mat-sort-header>Trajectory</div>
            <app-option-filter [values]="nameOptions" [inputFilter]="nameFilter()" (outputFilter)="filterName($event)"
          /></mat-header-cell>
          <mat-cell
            *matCellDef="let trajectory"
            class="fg-on-surface-variant border-outline-variant grow-0 basis-48 bg-white"
            >{{ trajectory.name }}</mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="detectionPercentage">
          <mat-header-cell *matHeaderCellDef class="surface border-outline-variant grow-0 basis-48"
            ><div mat-sort-header>Detection ratio</div>
            <app-filter
              [values]="filtersValues('detectionPercentage')()"
              [inputFilter]="detectionPercentageFilter()"
              (outputFilter)="filterDetectionPercentage($event)"
          /></mat-header-cell>
          <mat-cell
            *matCellDef="let trajectory"
            class="fg-on-surface-variant border-outline-variant grow-0 basis-48 bg-white"
            >{{ trajectory.detectionPercentage | number: '2.2-2' }}%</mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="frequency">
          <mat-header-cell *matHeaderCellDef class="surface border-outline-variant grow-0 basis-48">
            <div mat-sort-header>Frequency</div>
            <app-filter
              data-testid="filter-frequency"
              [values]="filtersValues('frequency')()"
              [inputFilter]="frequencyFilter()"
              (outputFilter)="filterFrequency($event)" />
          </mat-header-cell>
          <mat-cell
            *matCellDef="let trajectory"
            class="fg-on-surface-variant border-outline-variant grow-0 basis-48 bg-white"
            >{{ trajectory.frequency | number: '1.0-3' }} Hz</mat-cell
          >
        </ng-container>

        <ng-template #trendColumnDef let-column="column" let-trendType="trendType" let-colorFilter="colorFilter">
          <ng-container [matColumnDef]="column">
            <mat-header-cell *matHeaderCellDef class="surface border-outline-variant grow-0 basis-48 justify-center"
              ><mat-checkbox
                [checked]="allSelected(trendType)()"
                [indeterminate]="partiallySelected(trendType)()"
                (change)="trendTypeCheckboxChange(trendType, $event.checked)" />{{
                TrendType[trendType]
              }}</mat-header-cell
            >
            <mat-cell
              *matCellDef="let trajectory"
              class="fg-on-surface-variant border-outline-variant grow-0 basis-48 justify-center bg-white p-1"
              ><app-trend-miniature
                (click)="toggle(trendType, trajectory.id)"
                [selected]="isSelected(trendType, trajectory.id)"
                [attr.data-testid]="'visualize-' + trendType + '-' + trajectory.id"
                [contentPath]="getTrendContentPath(trajectory, trendType)"
                [colorFilter]="colorFilter" /></mat-cell
          ></ng-container>
        </ng-template>

        <ng-container
          *ngTemplateOutlet="
            trendColumnDef;
            context: { column: 'frequencyMiniature', trendType: TrendType.Frequency, colorFilter: 'purple-filter' }
          " />

        <ng-container
          *ngTemplateOutlet="
            trendColumnDef;
            context: { column: 'energyMiniature', trendType: TrendType.Energy, colorFilter: 'green-filter' }
          " />

        <ng-container matColumnDef="filler">
          <mat-header-cell *matHeaderCellDef class="surface border-outline-variant flex-grow rounded-tr-lg" />
          <mat-cell
            *matCellDef="let trajectory"
            class="fg-on-surface-variant border-outline-variant flex-grow bg-white" />
        </ng-container>

        <mat-header-row *matHeaderRowDef="columns; sticky: true" />
        <mat-row *matRowDef="let trajectory; columns: columns" />

        <div *matNoDataRow class="flex flex-row justify-center">
          <span>No trajectory found</span>
        </div>
      </mat-table>
    </app-scrollable-bordered-container>
    <mat-paginator [pageSizeOptions]="[10, 50, 100, 500]" showFirstLastButtons pageSize="50" class="mr-auto" />
  } @else {
    <span>Trajectories not computed for this sensor</span>
  }
</div>
