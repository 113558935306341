@let _hiddenSelectedHarmonicsCount = hiddenSelectedHarmonicsCount();

<app-graph-wrapper-base
  class="flex w-full flex-col"
  name="Harmonics"
  xTitle="Frequency (Hz)"
  [graph]="graph"
  [hasData]="true"
  graphHeight="60vh">
  <div class="relative h-full w-full" graph>
    <lib-gl-graph
      #graph
      class="h-full w-full"
      [data]="harmonicsGraphData()"
      xTitle="Frequency (Hz)"
      [yTitle]="yTitle()"
      id="harmonics-graph"
      [yScale]="yScale()"
      [lines]="visiblePeakLines()"
      [syncWithVerticalLines]="true"
      (canvasDimensionsChanged)="graphCanvasDimensions.set($event)"
      (xAxisRangeChanged)="visibleFrequenciesRange.set($event)" />

    <app-harmonics-markers
      class="absolute"
      [ngStyle]="axisOverlayStyle()"
      [harmonics]="emphasizedHarmonics()"
      [canvasDimensions]="graphCanvasDimensions()"
      [frequenciesRange]="visibleFrequenciesRange()" />
  </div>

  @if (_hiddenSelectedHarmonicsCount !== 0) {
    <div class="flex flex-row justify-center gap-4 px-8 py-4" headers>
      @let _many_hidden = _hiddenSelectedHarmonicsCount > 1;

      <mat-icon class="text-yellow-600">warning</mat-icon>
      <span class="my-auto text-sm">
        {{ _hiddenSelectedHarmonicsCount }} of the selected series' peaks {{ _many_hidden ? 'are' : 'is' }} hidden
        because of the graph legend
      </span>
    </div>
  }
  <app-peaks-graph-interactive-legend
    class="mx-8"
    [dbScale]="dbScale()"
    (dbScaleChange)="dbScale.set($event)"
    [curveOptions]="curveOptions"
    [peaks]="fusionPeaks()"
    (peaksPredicateChanged)="peaksVisibilityFilter.set($event)"
    settings />
</app-graph-wrapper-base>
