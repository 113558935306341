import { CommonModule } from '@angular/common';
import { Component, computed, input, output, viewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ContextMenuComponent } from '@components/context-menu/context-menu.component';
import { ScrollableBorderedContainerComponent } from '@components/scrollable-bordered-container/scrollable-bordered-container.component';
import { Equipment } from '@features/equipments/shared/interfaces/equipment.interface';
import { Sensor } from '@features/sensors/shared/interfaces/sensor.interface';
import { CreateSensorDialogService } from '@features/sensors/shared/services/create-sensor-dialog.service';
import { SensorsActions } from '@features/sensors/shared/store/sensors.actions';
import { MaterialModule } from '@modules/material.module';
import { Store } from '@ngrx/store';
import { DialogService } from '@services/dialog.service';

@Component({
  selector: 'app-equipment',
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    ContextMenuComponent,
    MatProgressBarModule,
    MatIconModule,
    MatToolbarModule,
    ScrollableBorderedContainerComponent,
  ],
  providers: [DialogService, CreateSensorDialogService],
  templateUrl: './equipment.component.html',
  styles: ``,
})
export class EquipmentComponent {
  private _sort = viewChild.required(MatSort);
  rightTrigger = viewChild<MatMenuTrigger>('rightTrigger');

  equipment = input.required<Equipment>();
  isLoading = input.required<boolean>();
  canEdit = input.required<boolean>();

  refreshClicked = output();
  sensorClicked = output<Sensor>();

  public columns = [
    'name',
    'sensorType',
    'model',
    'component',
    'orientation',
    'collectPoint',
    'signalsCount',
    'button',
  ];
  datasource = computed(() => {
    const sort = this._sort();
    const items = this.equipment().sensors;
    const datasource = new MatTableDataSource(items);
    datasource.sort = sort;
    return datasource;
  });

  public _menuOpened = false;
  public rightClickMenuPosition = { x: '0', y: '0' };

  constructor(
    private createSensorDialogService: CreateSensorDialogService,
    private dialogService: DialogService,
    private store: Store
  ) {}

  public numberOfSignals(sensor: Sensor) {
    const count = sensor.signalsCount;
    if (count > 0) {
      return `${count}`;
    } else {
      return '-';
    }
  }
  public onClick(sensor: Sensor) {
    this.sensorClicked.emit(sensor);
  }

  public onRightClick(event: MouseEvent, sensor: Sensor) {
    event.preventDefault();
    event.stopPropagation();
    this.rightClickMenuPosition.x = event.clientX + 'px';
    this.rightClickMenuPosition.y = event.clientY + 'px';
    this.rightTrigger()!.menuData = { item: sensor };
    this.rightTrigger()!.openMenu();
  }

  async delete(sensor: Sensor) {
    if (
      await this.dialogService.confirm({
        title: 'Delete sensor',
        descriptions: [
          'Deleting the sensor will delete all its signals.',
          'This action is irreversible, are you sure?',
        ],
      })
    ) {
      this.store.dispatch(SensorsActions.sensorDeleteRequested({ id: sensor.id, equipmentId: sensor.equipmentId }));
    }
  }

  async openCreateSensorDialog() {
    this.createSensorDialogService.open({ equipmentId: this.equipment().id });
  }

  openInNew(item: Sensor) {
    window.open(`/sensor/${item.id}`, '_blank');
  }

  public menuOpened() {
    this._menuOpened = true;
  }

  public menuClosed() {
    this._menuOpened = false;
  }
}
