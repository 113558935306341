import { IndexedDbRowId } from '@astrion-webtools/graph';

export interface TrajectoryTrendDto {
  id: string;
  name: string;
  interlacedValues: IndexedDbRowId;
}

export interface TrajectoryTrend {
  name: string;
  interlacedValues: IndexedDbRowId;
}

export enum TrendType {
  Frequency = 0,
  Energy,
}
