<app-graph-wrapper
  name="Shannon test"
  class="h-full w-full"
  id="sampling-test"
  [data]="data()"
  [lines]="lines()"
  [yScale]="dbScale"
  xTitle="Frequency (Hz)"
  yTitle="PSD (dB)"
  graphHeight="63vh"
  [showDelete]="false" />
