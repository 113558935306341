import { ChangeDetectionStrategy, Component, computed, input, signal } from '@angular/core';
import { GraphBoundedArea, GraphInput, Scaling } from '@astrion-webtools/graph';
import { FusionData, FusionPeak } from '@features/peak-identification/shared/interface/fusion';
import { peakToLine } from '@features/peak-identification/shared/utils/peak-utils';
import { MaterialModule } from '@modules/material.module';
import { ASTRION_INDEXEDDB_NAME, ASTRION_INDEXEDDB_TABLES } from '@shared/constants/astrion-indexeddb';
import { loaded } from '@shared/interfaces/loading-state';

import { ContentStyle } from '../content-toggle/content-toggle.component';
import {
  CurvesOptions,
  PeaksGraphInteractiveLegendComponent,
  SimplifiedPeak,
} from '../peaks-graph-interactive-legend/peaks-graph-interactive-legend.component';
import { PeaksTableComponent, TablePeak, TablePeakColumnDefinition } from '../peaks-table/peaks-table.component';
import { GraphWrapperComponent } from '@components/graph-wrapper/graph-wrapper.component';

@Component({
  selector: 'app-fusion',
  standalone: true,
  imports: [GraphWrapperComponent, MaterialModule, PeaksGraphInteractiveLegendComponent, PeaksTableComponent],
  templateUrl: './fusion.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FusionComponent {
  public fusion = input.required<FusionData>();

  public dbScale = signal(true);
  public yScale = computed(() => (this.dbScale() ? Scaling.dB : Scaling.None));

  public yTitle = computed(() => (this.dbScale() ? 'PSD (dB)' : 'PSD'));

  public showNoiseArea = signal<boolean>(true);

  public get noiseAreaStyle(): ContentStyle {
    return {
      color: '#078bf8bb',
    };
  }

  public get curveOptions(): CurvesOptions {
    return {
      name: 'Curves',
      first: {
        name: 'Noise',
        style: this.noiseAreaStyle,
        state: this.showNoiseArea,
      },
    };
  }

  public fusionPeaks = computed((): FusionPeak[] => this.fusion().peaks);

  public peaksTableAdditionalColumns = computed((): TablePeakColumnDefinition[] => {
    const fusion = this.fusion();

    return [
      {
        name: 'Best cycle',
        propertyName: 'bestCycleIndex',
        formattedPropertyValue: (peak: TablePeak) => {
          return `${(peak as unknown as FusionPeak).bestCycleIndex}`;
        },
      },
      {
        name: 'Fusion cycles',
        propertyName: 'cyclesCount',
        formattedPropertyValue: (peak: TablePeak) => {
          return `${(peak as unknown as FusionPeak).cyclesCount}/${fusion.cyclesCount}`;
        },
      },
    ];
  });

  public peaksFilter = signal<(peak: SimplifiedPeak) => boolean>(() => true);

  public fusionPeakLines = computed(() => {
    return this.fusionPeaks()
      .filter(this.peaksFilter())
      .map(peak => peakToLine(peak, false));
  });

  public fusionData = computed<GraphInput>(() => {
    const noiseArea = this.fusion().noiseArea;

    let area: GraphBoundedArea | undefined = undefined;

    if (loaded(noiseArea.minAmplitudes) && loaded(noiseArea.maxAmplitudes)) {
      area = {
        id: 'noiseArea',
        color: this.noiseAreaStyle.color,
        dataScale: Scaling.dB,
        minCurve: {
          id: 'min',
          data: {
            indexedDb: {
              id: noiseArea.minAmplitudes.data!,
              valuesField: 'data',
              xMinField: 'freqMin',
              xMaxField: 'freqMax',
            },
          },
        },
        maxCurve: {
          id: 'max',
          data: {
            indexedDb: {
              id: noiseArea.maxAmplitudes.data!,
              valuesField: 'data',
              xMinField: 'freqMin',
              xMaxField: 'freqMax',
            },
          },
        },
      };
    }

    return {
      dbName: ASTRION_INDEXEDDB_NAME,
      storeName: ASTRION_INDEXEDDB_TABLES.fusion,
      areas: area === undefined ? undefined : [area],
    };
  });
}
