@if (cycleSpectrumData(); as spectrum) {
  @let _welchWOSA = welchWOSA();

  <table class="mx-auto mt-6 w-1/2 rounded border text-left">
    <thead>
      <tr class="bg-slate-500 text-center uppercase text-white">
        <th colspan="2" class="px-6 py-4">Estimation for Spectrum {{ cycleIndex() + 1 }}</th>
      </tr>
    </thead>
    <tbody>
      <tr class="border-b odd:bg-slate-50 even:bg-slate-100">
        <th class="px-6 py-4">Method</th>
        <td class="px-6 py-4 text-right">{{ spectrum.method }}</td>
      </tr>
      <tr class="border-b odd:bg-slate-50 even:bg-slate-100">
        <th class="px-6 py-4">Signal length</th>
        <td class="px-6 py-4 text-right">{{ spectrum.signalLength }}</td>
      </tr>
      <tr class="border-b odd:bg-slate-50 even:bg-slate-100">
        <th class="px-6 py-4">{{ _welchWOSA ? '' : 'Autocorrelation' }} Window type</th>
        <td class="px-6 py-4 text-right">{{ spectrum.windowType }}</td>
      </tr>
      <tr class="border-b odd:bg-slate-50 even:bg-slate-100">
        <th class="px-6 py-4">Window length{{ _welchWOSA ? '' : ' (positive delays)' }}</th>
        <td class="px-6 py-4 text-right">{{ spectrum.windowLength }}</td>
      </tr>
      @if (_welchWOSA) {
        <tr class="border-b odd:bg-slate-50 even:bg-slate-100">
          <th class="px-6 py-4">Window overlap</th>
          <td class="px-6 py-4 text-right">{{ spectrum.overlap }}</td>
        </tr>
        @if (showSegmentsCount()) {
          <tr class="border-b odd:bg-slate-50 even:bg-slate-100">
            <th class="px-6 py-4">Number of segments</th>
            <td class="px-6 py-4 text-right">{{ spectrum.segmentsCount }}</td>
          </tr>
        }
      }
      <tr class="border-b odd:bg-slate-50 even:bg-slate-100">
        <th class="px-6 py-4">Next pow 2 FFT length</th>
        <td class="px-6 py-4 text-right">{{ spectrum.fftLength }}</td>
      </tr>
      <tr class="border-b odd:bg-slate-50 even:bg-slate-100">
        <th class="px-6 py-4">Normalization</th>
        <td class="px-6 py-4 text-right">{{ spectrum.normalizationType }}</td>
      </tr>
      <tr class="border-b odd:bg-slate-50 even:bg-slate-100">
        <th class="px-6 py-4">Frequency bin</th>
        <td class="px-6 py-4 text-right">{{ frequencyBin() }} Hz</td>
      </tr>
      <tr class="border-b odd:bg-slate-50 even:bg-slate-100">
        <th class="px-6 py-4">Frequency resolution</th>
        <td class="px-6 py-4 text-right">{{ frequencyResolution() }} Hz</td>
      </tr>
      <tr class="border-b odd:bg-slate-50 even:bg-slate-100">
        <th class="px-6 py-4">Normalized variance</th>
        <td class="px-6 py-4 text-right">
          {{ normalizedVariance() }}
        </td>
      </tr>
    </tbody>
  </table>
}

@if (cycleNoiseData(); as noise) {
  <table class="mx-auto mt-6 w-1/2 rounded border text-left">
    <thead>
      <tr class="bg-slate-500 text-center uppercase text-white">
        <th colspan="2" class="px-6 py-4">Noise estimation for Spectrum {{ cycleIndex() + 1 }}</th>
      </tr>
    </thead>
    <tbody>
      <tr class="border-b odd:bg-slate-50 even:bg-slate-100">
        <th class="px-6 py-4">Filter type</th>
        <td class="px-6 py-4 text-right">{{ noise.filterType }}</td>
      </tr>
      <tr class="border-b odd:bg-slate-50 even:bg-slate-100">
        <th class="px-6 py-4">Filter frequency bandwidth (x zerocrossing-based bandwidth of spectral window)</th>
        <td class="px-6 py-4 text-right">{{ noise.filterFrequencyBandwidth }}</td>
      </tr>
      <tr class="border-b odd:bg-slate-50 even:bg-slate-100">
        <th class="px-6 py-4">Filter PFA</th>
        <td class="px-6 py-4 text-right">{{ noise.filterPFA }}</td>
      </tr>
      <tr class="border-b odd:bg-slate-50 even:bg-slate-100">
        <th class="px-6 py-4">Filter length</th>
        <td class="px-6 py-4 text-right">{{ noise.filterLength }}</td>
      </tr>
    </tbody>
  </table>
}
