import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MaterialModule } from '@modules/material.module';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  standalone: true,
  imports: [MaterialModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextMenuComponent {
  menuTrigger = input.required<MatMenuTrigger>();
  allowOpenInNew = input<boolean>(false);
  allowDownload = input<boolean>(false);
  allowEdit = input<boolean>(false);
  allowDelete = input<boolean>(false);

  openInNew = output();
  rename = output();
  delete = output();
  download = output();
}
