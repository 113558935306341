import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, signal } from '@angular/core';
import { AxisRange, GlGraphComponent, GraphBoundedArea, GraphInput, Scaling } from '@astrion-webtools/graph';
import { GraphCanvasDimensions } from '@astrion-webtools/graph';
import { FusionData, FusionPeak } from '@features/peak-identification/shared/interface/fusion';
import { HarmonicSerie } from '@features/peak-identification/shared/interface/harmonic-series';
import { peakToLine } from '@features/peak-identification/shared/utils/peak-utils';
import { MaterialModule } from '@modules/material.module';
import { ASTRION_INDEXEDDB_NAME, ASTRION_INDEXEDDB_TABLES } from '@shared/constants/astrion-indexeddb';
import { loaded } from '@shared/interfaces/loading-state';

import { ContentStyle } from '../../content-toggle/content-toggle.component';
import {
  CurvesOptions,
  PeaksGraphInteractiveLegendComponent,
  SimplifiedPeak,
} from '../../peaks-graph-interactive-legend/peaks-graph-interactive-legend.component';
import { ColoredHarmonicSerie } from '../shared/interfaces';
import { HarmonicsMarkersComponent } from './harmonics-markers/harmonics-markers.component';
import { GraphWrapperBaseComponent } from '@components/graph-wrapper-base/graph-wrapper-base.component';

const NoiseAreaStyle: ContentStyle = {
  color: '#078bf8bb',
};

interface HarmonicsSortedBySelectionStatus {
  selected: FusionPeak[];
  unselected: FusionPeak[];
}

@Component({
  selector: 'app-harmonics-graph',
  standalone: true,
  imports: [
    CommonModule,
    GraphWrapperBaseComponent,
    GlGraphComponent,
    HarmonicsMarkersComponent,
    MaterialModule,
    PeaksGraphInteractiveLegendComponent,
  ],
  templateUrl: './harmonics-graph.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HarmonicsGraphComponent {
  public fusion = input.required<FusionData>();
  public harmonicSeries = input.required<ColoredHarmonicSerie[]>();

  public seriesSelectionFilter = input<(serie: HarmonicSerie) => boolean>(() => false);

  public readonly curveOptions: CurvesOptions = {
    name: 'Curves',
    first: {
      name: 'Noise',
      style: NoiseAreaStyle,
      state: signal<boolean>(true),
    },
  };

  public peaksVisibilityFilter = signal<(peak: SimplifiedPeak) => boolean>(() => true);

  public dbScale = signal(true);

  public graphCanvasDimensions = signal<GraphCanvasDimensions>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  public visibleFrequenciesRange = signal<AxisRange>({
    min: 0,
    max: 1,
  });

  public fusionPeaks = computed((): FusionPeak[] => this.fusion().peaks);

  public yScale = computed(() => (this.dbScale() ? Scaling.dB : Scaling.None));
  public yTitle = computed(() => (this.dbScale() ? 'PSD (dB)' : 'PSD'));

  public hiddenSelectedHarmonicsCount = computed(() => {
    const selectedHarmonics = this._harmonicsSortedBySelectionStatus().selected;

    const visibleSelectedHarmonics = selectedHarmonics.filter(this.peaksVisibilityFilter());

    return selectedHarmonics.length - visibleSelectedHarmonics.length;
  });

  public axisOverlayStyle = computed(() => {
    const dimensions = this.graphCanvasDimensions();

    return {
      width: `${dimensions.width}px`,
      height: '40px',
      top: `${dimensions.y + dimensions.height}px`,
      left: `${dimensions.x}px`,
    };
  });

  public harmonicsGraphData = computed<GraphInput>(() => {
    const noiseArea = this.fusion().noiseArea;

    let area: GraphBoundedArea | undefined = undefined;

    if (loaded(noiseArea.minAmplitudes) && loaded(noiseArea.maxAmplitudes)) {
      area = {
        id: 'noiseArea',
        color: NoiseAreaStyle.color,
        dataScale: Scaling.dB,
        minCurve: {
          id: 'min',
          data: {
            indexedDb: {
              id: noiseArea.minAmplitudes.data!,
              valuesField: 'data',
              xMinField: 'freqMin',
              xMaxField: 'freqMax',
            },
          },
        },
        maxCurve: {
          id: 'max',
          data: {
            indexedDb: {
              id: noiseArea.maxAmplitudes.data!,
              valuesField: 'data',
              xMinField: 'freqMin',
              xMaxField: 'freqMax',
            },
          },
        },
      };
    }

    return {
      dbName: ASTRION_INDEXEDDB_NAME,
      storeName: ASTRION_INDEXEDDB_TABLES.fusion,
      areas: area === undefined ? undefined : [area],
    };
  });

  public visiblePeakLines = computed(() => {
    const peaksVisibilityFilter = this.peaksVisibilityFilter();

    const nonHarmonicPeaksLines = this._nonHarmonicPeaks()
      .filter(peaksVisibilityFilter)
      .map(peak => peakToLine(peak, true));

    const sortedHarmonics = this._harmonicsSortedBySelectionStatus();

    const coloredPeaksLines = sortedHarmonics.selected
      .filter(this.peaksVisibilityFilter())
      .map(peak => peakToLine(peak, false));

    const grayedOutHarmonicPeaksLines = sortedHarmonics.unselected
      .filter(this.peaksVisibilityFilter())
      .map(peak => peakToLine(peak, true));

    const grayedOutPeaksLines = nonHarmonicPeaksLines.concat(...grayedOutHarmonicPeaksLines);

    return coloredPeaksLines.concat(...grayedOutPeaksLines);
  });

  public emphasizedHarmonics = computed(() => {
    const selectedSeries = this._selectedSeries();

    if (selectedSeries.length < 1) {
      return [];
    }

    const harmonics = this._harmonicsSortedBySelectionStatus().selected;

    const selectedHarmonics = selectedSeries
      .map(serie => {
        const serieHarmonics = serie.harmonics.map(harmonic => {
          // The corresponding peak will necessarily be found here, by definition.
          const correspondingPeak = harmonics.find(peak => peak.index === harmonic.peakIndex)!;

          return {
            ...harmonic,
            frequency: correspondingPeak?.frequency,
            color: serie.color,
          };
        });

        const maxSerieRank = serie.harmonics[serie.harmonics.length - 1].rank;
        const rankList = [...Array(maxSerieRank).keys()].map(index => index + 1);

        const allHarmonics = rankList.map(rank => {
          const correspondingHarmonic = serieHarmonics.find(harmonic => harmonic.rank === rank);

          if (correspondingHarmonic !== undefined) {
            return correspondingHarmonic;
          } else {
            return {
              peakIndex: -1,
              rank,
              frequency: serie.fundamentalFrequency * rank,
              color: serie.color,
            };
          }
        });

        return allHarmonics;
      })
      .flat();

    selectedHarmonics.sort((h1, h2) => h1.frequency - h2.frequency);

    const reduced = selectedHarmonics.slice(1).reduce(
      (harmonics, harmonic) => {
        const latestIndex = harmonics.length - 1;

        if (harmonic.frequency !== harmonics[latestIndex].frequency) {
          harmonics.push(harmonic);
        } else if (harmonic.rank > harmonics[latestIndex].rank) {
          harmonics[latestIndex] = harmonic;
        }

        return harmonics;
      },
      [selectedHarmonics[0]]
    );

    return reduced;
  });

  private _selectedSeries = computed(() => this.harmonicSeries().filter(this.seriesSelectionFilter()));

  private _harmonics = computed(() => {
    const seriesPeaks = this.harmonicSeries()
      .map(serie => serie.harmonics)
      .flat()
      .map(harmonic => harmonic.peakIndex);

    const uniquePeakIndices = seriesPeaks.filter((peak, index, peaks) => peaks.indexOf(peak) === index);

    uniquePeakIndices.sort((a, b) => a - b);

    return this.fusionPeaks().filter(peak => uniquePeakIndices.includes(peak.index));
  });

  private _nonHarmonicPeaks = computed(() => {
    const harmonicSeriesPeaks = this._harmonics();

    return this.fusionPeaks().filter(peak => !harmonicSeriesPeaks.includes(peak));
  });

  private _selectedHarmonicIndices = computed(() => {
    const uniqueSelectedSeriesPeaks = this._selectedSeries()
      .map(serie => serie.harmonics)
      .flat()
      .map(harmonic => harmonic.peakIndex)
      .filter((peak, index, peaks) => peaks.indexOf(peak) === index);

    uniqueSelectedSeriesPeaks.sort();

    return uniqueSelectedSeriesPeaks;
  });

  private _harmonicsSortedBySelectionStatus = computed(() => {
    const harmonics = this._harmonics();

    const selectedPeaksIndices = this._selectedHarmonicIndices();

    const harmonicSortedBySelectionStatus: HarmonicsSortedBySelectionStatus = {
      selected: [],
      unselected: [],
    };

    harmonics.reduce((peaks, currentPeak) => {
      if (selectedPeaksIndices.includes(currentPeak.index)) {
        peaks.selected.push(currentPeak);
      } else {
        peaks.unselected.push(currentPeak);
      }

      return peaks;
    }, harmonicSortedBySelectionStatus);

    return harmonicSortedBySelectionStatus;
  });
}
