import { Injectable } from '@angular/core';
import { AuthActions } from '@features/auth/shared/store/auth.actions';
import { catchApiError } from '@modules/error-handling/app-error.operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { concatMap, map, tap } from 'rxjs';

import { EquipmentDto, mapEquipmentFromDto } from '../interfaces/equipment.interface';
import { EquipmentsApiService } from '../services/equipments-api.service';
import { EquipmentsActions } from './equipments.actions';
import { equipmentsFeature } from './equipments.feature';
import { Router } from '@angular/router';

@Injectable()
export class EquipmentsEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private router: Router,
    private equipmentsApiService: EquipmentsApiService
  ) {}

  refreshEquipmentsIfNotInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EquipmentsActions.refreshIfNotInit),
      map(() => EquipmentsActions.equipmentsFetchRequestedIfNotInit())
    )
  );

  fetchEquipmentsIfNotInit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EquipmentsActions.equipmentsFetchRequestedIfNotInit),
      concatLatestFrom(() => this.store.select(equipmentsFeature.selectInitialized)),
      map(([, initialized]) => (initialized ? EquipmentsActions.noop() : EquipmentsActions.equipmentsFetchRequested()))
    );
  });

  fetchEquipmentsEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EquipmentsActions.equipmentsFetchRequested, AuthActions.projectChanged),
      concatMap(() =>
        this.equipmentsApiService.getEquipments().pipe(
          map((equipments: EquipmentDto[]) =>
            EquipmentsActions.equipmentsFetched({ equipments: equipments.map(mapEquipmentFromDto) })
          ),
          catchApiError(false, () => EquipmentsActions.equipmentsFetchFailed())
        )
      )
    );
  });

  createEquipmentEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EquipmentsActions.equipmentCreateRequested),
      concatMap(({ equipment }) =>
        this.equipmentsApiService.createEquipment(equipment).pipe(
          map(created => EquipmentsActions.equipmentCreated({ equipment: mapEquipmentFromDto(created) })),
          catchApiError(false, () => EquipmentsActions.equipmentCreateFailed())
        )
      )
    );
  });

  deleteEquipmentEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EquipmentsActions.equipmentDeleteRequested),
      concatMap(({ id }) =>
        this.equipmentsApiService.deleteEquipment(id).pipe(
          map(() => EquipmentsActions.equipmentDeleted({ id })),
          catchApiError(false, () => EquipmentsActions.equipmentCreateFailed())
        )
      )
    );
  });

  navigateOnEquipmentDeletionEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EquipmentsActions.equipmentDeleted),
        concatLatestFrom(() => [this.store.select(equipmentsFeature.selectCurrentEquipmentId)]),
        tap(([{ id }, currentEquipmentId]) => {
          if (id === currentEquipmentId) {
            this.router.navigateByUrl(`/operation`);
          }
        })
      );
    },
    { dispatch: false }
  );
}
